import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
    index: number;
    surveyProgress: number;
}

const ProgressBar = (props: ProgressBarProps): JSX.Element => {
    const { index, surveyProgress } = props;

    const isActive = index <= surveyProgress;

    return (
        <Progress
            style={{ backgroundColor: isActive ? '#32e344' : 'white' }}
        />
    );
};

export default ProgressBar;

const Progress = styled.div`
    width: 30px;
    height: 100%;

    position: relative;
    border-radius: 1rem;
`;
