import React from 'react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Full from 'containers/Full';
import config from 'config';
import './App.css';

const App = (): JSX.Element => {
    ReactGA.initialize(config.ga4TrackingId);
    return (
        <div className='App'>
            <Full />
            <ToastContainer theme='colored' />
        </div>
    );
};

export default App;
