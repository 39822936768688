import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface GetRewardsParams {
    scanId: string;
}

export interface GetRewardsResponse {
    code: string;
    expiry: string;
    value: number;
    copyData?: {
        answeredYes: boolean;
        url: string;
    }
}

export abstract class IRewardsGateway extends Gateway {
    abstract getRewards(params: GetRewardsParams): GatewayResponse<GetRewardsResponse>;
}
