import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';
import {
    TrackScanParams,
    TrackScanResponse,
    IHomeGateway,
    SelectLanguageParams,
    SetDOBParams,
    SetPhoneNumberParams,
    SubmitUniqueCodeParams,
    SubmitUniqueCodeResponse,
    SubmitOTPParams,
    ResendOTPParams,
    SubmitBasicInfoParams,
    SubmitBasicInfoResponse,
    SubmitSurveyStatusParams,
    GetScanInfoParams,
    GetScanInfoResponse,
    SelectLanguageAndDOBParams,
    SubmitUniqueCodeAndPhoneNumberParams,
    SubmitBasicInfoAndSurveyStatusParams,
    SubmitBasicInfoAndSurveyStatusResponse,
    SubmitOTPResponse,
    GetCommonCopiesParams,
    GetCommonCopiesResponse,
    GetLanguagePackParams,
    GetLanguagePackResponse,
    TrackDisclaimerEngagementCheckboxParams,
    TrackStageTimeParams,
} from './HomeBase';

const getHeaders = (scanId: string) => ({
    headers: {
        'X-Scan-Id': scanId,
    },
});

export default class HomeGateway extends IHomeGateway {
    async trackScan(params: TrackScanParams): GatewayResponse<TrackScanResponse> {
        const response: ApiResponse<TrackScanResponse> = await this.api.post('/c/scan', params);
        return this.process(response);
    }

    async selectLanguage(params: SelectLanguageParams): GatewayResponse<null> {
        const { scanId, ...restOfSelectLanguageParams } = params;
        const response: ApiResponse<null> = await this.api.post('/c/selectLang', restOfSelectLanguageParams, getHeaders(scanId));
        return this.process(response);
    }

    async setDOB(params: SetDOBParams): GatewayResponse<null> {
        const { scanId, dob } = params;
        const response: ApiResponse<null> = await this.api.post('/c/dob', { dob }, getHeaders(scanId));
        return this.process(response);
    }

    async setPhoneNumber(params: SetPhoneNumberParams): GatewayResponse<null> {
        const { phoneNumber, scanId } = params;
        const response: ApiResponse<null> = await this.api.post('/c/phoneNumber', { phoneNumber }, getHeaders(scanId));
        return this.process(response);
    }

    async submitUniqueCode(params: SubmitUniqueCodeParams): GatewayResponse<SubmitUniqueCodeResponse> {
        const { scanId, code } = params;
        const response: ApiResponse<SubmitUniqueCodeResponse> = await this.api.post('/c/uniqueCode', { code }, getHeaders(scanId));
        return this.process(response);
    }

    async submitOTP(params: SubmitOTPParams): GatewayResponse<SubmitOTPResponse> {
        const { scanId, otp } = params;
        const response: ApiResponse<SubmitOTPResponse> = await this.api.post('/c/otp', { otp }, getHeaders(scanId));
        return this.process(response);
    }

    async resendOTP(params: ResendOTPParams): GatewayResponse<null> {
        const { scanId } = params;
        const response: ApiResponse<null> = await this.api.post('/c/resendOtp', {}, getHeaders(scanId));
        return this.process(response);
    }

    async submitBasicInfo(params: SubmitBasicInfoParams): GatewayResponse<SubmitBasicInfoResponse> {
        const { scanId, ...restOfSubmitBasicInfoParams } = params;
        const response: ApiResponse<SubmitBasicInfoResponse> = await this.api.post('/c/basicInfo', restOfSubmitBasicInfoParams, getHeaders(scanId));
        return this.process(response);
    }

    async submitSurveyStatus(params: SubmitSurveyStatusParams): GatewayResponse<null> {
        const { scanId, isTrialist } = params;
        const response: ApiResponse<null> = await this.api.post('/c/surveyStatus', { isTrialist }, getHeaders(scanId));
        return this.process(response);
    }

    async getScanInfo(params: GetScanInfoParams): GatewayResponse<GetScanInfoResponse> {
        const { scanId } = params;
        const response: ApiResponse<GetScanInfoResponse> = await this.api.get('/c/scanInfo', {}, getHeaders(scanId));
        return this.process(response);
    }

    async selectLanguageAndDOB(params: SelectLanguageAndDOBParams): GatewayResponse<null> {
        const { scanId, ...restOfSelectLanguageAndDOBParams } = params;
        const response: ApiResponse<null> = await this.api.post('/c/v2/selectLangAndDob', restOfSelectLanguageAndDOBParams, getHeaders(scanId));
        return this.process(response);
    }

    async submitUniqueCodeAndPhoneNumber(params: SubmitUniqueCodeAndPhoneNumberParams): GatewayResponse<SubmitUniqueCodeResponse> {
        const { scanId, ...restOfSubmitUniqueCodeAndPhoneNumberParams } = params;
        const response: ApiResponse<SubmitUniqueCodeResponse> = await this.api.post('/c/v2/uniqueCodeAndPhoneNumber', restOfSubmitUniqueCodeAndPhoneNumberParams, getHeaders(scanId));
        return this.process(response);
    }

    async submitBasicInfoAndSurveyStatus(params: SubmitBasicInfoAndSurveyStatusParams): GatewayResponse<SubmitBasicInfoAndSurveyStatusResponse> {
        const { scanId, ...restOfSubmitBasicInfoAndSurveyStatusParams } = params;
        const response: ApiResponse<SubmitBasicInfoAndSurveyStatusResponse> = await this.api.post('/c/v2/basicInfoAndSurveyStatus', restOfSubmitBasicInfoAndSurveyStatusParams, getHeaders(scanId));
        return this.process(response);
    }

    async getCommonCopies(params: GetCommonCopiesParams): GatewayResponse<GetCommonCopiesResponse> {
        const response: ApiResponse<GetCommonCopiesResponse> = await this.api.get('/c/commonCopies', params);
        return this.process(response);
    }

    async getLanguagePack(params: GetLanguagePackParams): GatewayResponse<GetLanguagePackResponse> {
        const { scanId, ...restOfGetLanguagePackParams } = params;
        const response: ApiResponse<GetLanguagePackResponse> = await this.api.get('/c/languagePack', restOfGetLanguagePackParams, getHeaders(scanId));
        return this.process(response);
    }

    async trackDOB(params: SetDOBParams): GatewayResponse<null> {
        const { scanId, ...restOfSetDOBParams } = params;
        const response: ApiResponse<null> = await this.api.post('/c/v2/dob', restOfSetDOBParams, getHeaders(scanId));
        return this.process(response);
    }

    async trackDisclaimerCheckboxEngagement(params: TrackDisclaimerEngagementCheckboxParams): GatewayResponse<null> {
        const { scanId, ...restOfTrackDisclaimerEngagementParams } = params;

        const response: ApiResponse<null> = await this.api.post('c/v2/trackDisclaimerCheckboxEngagement', restOfTrackDisclaimerEngagementParams, getHeaders(scanId));
        return this.process(response);
    }

    async trackStageTime(params: TrackStageTimeParams): GatewayResponse<null> {
        const { scanId, ...restOfTrackStageTimeParams } = params;
        const response: ApiResponse<null> = await this.api.post('/c/v2/trackTimeTakenOnStage', restOfTrackStageTimeParams, getHeaders(scanId));
        return this.process(response);
    }
}
