import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Slider } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IOption, IDataToSubmit } from 'entities/surveys';

import Text from 'components/Text';

import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/SlidingTenStyles';

interface CustomSliderProps {
    selected: boolean;
}

const CustomSlider = styled(Slider) <CustomSliderProps>`
    padding: 5;
    width: 100%;
    margin-bottom: 20px;

    .MuiSlider-thumb {
        background-color: ${props => (props.selected ? '#021778' : 'transparent')};
        border: none;
        box-shadow: none;

        height: ${props => (props.selected ? '25px' : '0px')};
        width: ${props => (props.selected ? '25px' : '0px')};
    }

    .MuiSlider-rail {
        background-color: #d8dce5;
        opacity: 1;
        border-radius: 0;
        height: 6px;
    }

    .MuiSlider-track {
        background-color: #021778;
        opacity: 1;
        border-radius: 0;
        height: 6px;
        border: none;
    }

    .MuiSlider-mark {
        width: 1px;
        height: 15px;
        opacity: 1;
        background-color: #d8dce5;
    }

    .MuiSlider-markActive {
        background-color: ${props => (props.selected ? '#021778' : '#d8dce5')};
    }

    .MuiSlider-markLabel {
        color: #888888;
    }
`;

interface SlidingTenProps {
    surveyId: string;
    questionId: string;
    question: string;
    options: IOption[];
    images: string[];
    loading: boolean;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const SlidingTen: FunctionComponent<SlidingTenProps> = (props: SlidingTenProps) => {
    const { surveyId, questionId, question, options, images, changeQuestionStatus, submitAnswer, loading } = props;
    const [sliderValue, setSliderValue] = useState<number | number[]>(0);
    const [selected, setSelected] = useState(false);

    const sliderHandler = (value: number | number[]) => {
        const selectedItem = options.find(item => item.key === value);

        const dataToSubmit = {
            surveyId,
            questionId,
            selectedOptionId: selectedItem?.id || '',
        };

        changeQuestionStatus(questionId, true);

        submitAnswer(dataToSubmit);
    };

    const parsedSliderOptions = options.map((item, index) => {
        return {
            value: index + 1,
            label: item.value,
        };
    });

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {renderQuestion()}

            {images.length > 0 && (
                <Grid
                    isOnlyOne={(images.length === 1)}
                >
                    {images.map(url => {
                        return (
                            <img
                                key={url}
                                src={url}
                                alt='no'
                                id='img'
                                style={{ pointerEvents: 'none' }}
                            />
                        );
                    })}
                </Grid>
            )}

            <div
                style={ContainerStyles.outerSliderContainer}
            >
                <div style={ContainerStyles.innerSliderContainer}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '40px',
                        }}
                    >
                        <CustomSlider
                            min={1}
                            max={10}
                            step={1}
                            value={sliderValue}
                            onChange={(e, value) => { setSelected(true); setSliderValue(value); }}
                            onChangeCommitted={(e, value) => sliderHandler(value)}
                            marks={parsedSliderOptions}
                            selected={selected}
                        />
                    </div>
                </div>

                {loading && (
                    <div style={ContainerStyles.loadingOverlay} />
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

export default connect(null, mapDispatchToProps)(SlidingTen);
