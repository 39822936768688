import { RewardsState } from '.';

const getRewardsAttempting = (state: RewardsState): boolean => state.actions.getRewards || false;
const getRewardsError = (state: RewardsState): string => state.error.getRewards || '';

const getRewardsCode = (state: RewardsState): string => state.code || '';
const getRewardsExpiry = (state: RewardsState): string => state.expiry || '';
const getRewardsValue = (state: RewardsState): number => state.value || -1;

const getRewardsAnsweredYes = (state: RewardsState): boolean | undefined => state.answeredYes ?? undefined;
const getRewardsUrl = (state: RewardsState): string => state.url || '';

const getRewardsNoRewardsLeft = (state: RewardsState): boolean => state.noRewardsLeft || false;

export default {
    getRewardsAttempting,
    getRewardsError,

    getRewardsCode,
    getRewardsExpiry,
    getRewardsValue,

    getRewardsAnsweredYes,
    getRewardsUrl,
    getRewardsNoRewardsLeft,
};
