import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { ITrackScanParams } from 'redux/slices/home/types';
import { AppDispatch, RootState } from 'redux/store';

import { mobileModel, isMobileOnly, isTablet, isDesktop, isAndroid, isIOS, isWindows, isMacOs, browserName } from 'react-device-detect';
import { Modal, ModalHeader, Spinner } from 'reactstrap';
import NavActions from 'lib/NavActions';

interface HomeScreenProps {
    trackingScan: boolean;
    trackScanError: string;
    trackScan: (params: ITrackScanParams) => void;
}

type ParamState = {
    qrId: string;
}

const HomeScreen = (props: HomeScreenProps): JSX.Element => {
    const {
        trackingScan,
        trackScanError,
        trackScan,
    } = props;

    const param = useParams();
    const { qrId } = (param || { qrId: '' }) as ParamState;

    const [ip, setIp] = useState<string>();
    const [scanErrorModalIsOpen, setScanErrorModalIsOpen] = useState(false);

    const getIp = () => {
        setIp('0.0.0.0');
        // return timeout(3000, fetch('https://api.ipify.org?format=json'))
        //     .then((response: any) => {
        //         response.json();
        //     })
        //     .then((data: any) => setIp(data.ip))
        //     .catch(() => {
        //         return timeout(3000, fetch('https://api.db-ip.com/v2/free/self'))
        //             .then((response: any) => response.json())
        //             .then((data: any) => setIp(data.ipAddress))
        //             .catch(() => {
        //                 return timeout(3000, fetch('https://ipgeolocation.abstractapi.com/v1/?api_key='))
        //                     .then((response: any) => response.json())
        //                     .then((data: any) => {
        //                         if (data.error) throw new Error('No IP');
        //                         setIp(data.ip_address);
        //                     })
        //                     .catch(() => setIp('0.0.0.0'));
        //             });
        //     });
    };

    useEffect(() => {
        getIp();
    }, []);

    useEffect(() => {
        if (qrId) {
            let device;
            let os;

            if (isMobileOnly) {
                device = 1;
            } if (isTablet) {
                device = 2;
            } if (isDesktop) {
                device = 3;
            } else device = 4;

            if (isAndroid) {
                os = 1;
            } if (isIOS) {
                os = 2;
            } if (isWindows) {
                os = 3;
            } if (isMacOs) {
                os = 4;
            } else os = 5;

            if (ip !== undefined) {
                trackScan({ qrId, ipAddress: ip, mobileModel, deviceType: device, os, browser: browserName });
            }
        } if (!qrId) {
            NavActions.navToError();
        }
    }, [qrId, ip]);

    useEffect(() => {
        if (trackScanError) {
            setScanErrorModalIsOpen(true);
        }
    }, [trackScanError]);

    const renderScanErrorModal = () => {
        return (
            <Modal centered isOpen={scanErrorModalIsOpen}>
                <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{trackScanError}</div>
                        Thank you for participating.
                        <br />
                        <br />
                        Try contacting the owner of the form if you think this is a mistake.
                    </div>
                </ModalHeader>
            </Modal>
        );
    };

    if (trackingScan) {
        return (
            <div style={{
                height: '100vh',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            >
                <div style={{ margin: 'auto' }}>
                    <Spinner type='grow' />
                    <Spinner type='grow' style={{ margin: '0px 5px' }} />
                    <Spinner type='grow' />
                </div>
            </div>
        );
    }

    return (
        <div style={{ height: 'auto' }}>
            {renderScanErrorModal()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    trackingScan: Selectors.homeGetTrackScanAttempting(state),
    trackScanError: Selectors.homeGetTrackScanError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    trackScan: (params: ITrackScanParams) => dispatch(Actions.homeTrackScanAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
