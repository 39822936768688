import { IQrTypeEnum, ISurveyScanStageEnum, LanguageSelectionEnum } from 'api/HomeBase';
import { HomeState } from '.';
import { ICommonCopies, ILanguagePack } from './types';

const getTrackScanAttempting = (state: HomeState): boolean => state.actions.trackScan || false;
const getTrackScanError = (state: HomeState): string => state.error.trackScan || '';
const getScanId = (state: HomeState): string => state.scanId || '';
const getQrId = (state: HomeState): string => state.qrId || '';
const getQrType = (state: HomeState): IQrTypeEnum | undefined => state.qrType || undefined;

const getTrackDOBAttempting = (state: HomeState): boolean => state.actions.trackDob || false;

const getSelectLanguageAttempting = (state: HomeState): boolean => state.actions.selectLanguage || false;
const getSelectLanguageError = (state: HomeState): string => state.error.selectLanguage || '';
const getSelectedLanguage = (state: HomeState): LanguageSelectionEnum => state.language;

const getSetDOBAttempting = (state: HomeState): boolean => state.actions.setDOB || false;
const getSetDOBError = (state: HomeState): string => state.error.setDOB || '';
const getDob = (state: HomeState): string => state.dob || '';

const getSetPhoneNumberAttempting = (state: HomeState): boolean => state.actions.setPhoneNumber || false;
const getSetPhoneNumberError = (state: HomeState): string => state.error.setPhoneNumber || '';
const getPhoneNumber = (state: HomeState): string => state.phoneNumber || '';

const getSubmitOTPAttempting = (state: HomeState): boolean => state.actions.submitOTP || false;
const getSubmitOTPError = (state: HomeState): string => state.error.submitOTP || '';

const getResendOTPAttempting = (state: HomeState): boolean => state.actions.resendOTP || false;
const getResendOTPError = (state: HomeState): string => state.error.resendOTP || '';

const getSubmitBasicInfoAttempting = (state: HomeState): boolean => state.actions.submitBasicInfo || false;
const getSubmitBasicInfoError = (state: HomeState): string => state.error.submitBasicInfo || '';
const getIsTrialist = (state: HomeState): boolean | undefined => state.isTrialist ?? undefined;

const getScanInfoAttempting = (state: HomeState): boolean => state.actions.getScanInfo || false;
const getScanInfoError = (state: HomeState): string => state.error.getScanInfo || '';
const getStage = (state: HomeState): ISurveyScanStageEnum => state.stage || 0;

const getSelectLanguageAndDOBAttempting = (state: HomeState): boolean => state.actions.selectLanguageAndDOB || false;
const getSelectLanguageAndDOBError = (state: HomeState): string => state.error.selectLanguageAndDOB || '';

const getSubmitUniqueCodeAndPhoneNumberAttempting = (state: HomeState): boolean => state.actions.submitUniqueCodeAndPhoneNumber || false;
const getSubmitUniqueCodeAndPhoneNumberError = (state: HomeState): string => state.error.submitUniqueCodeAndPhoneNumber || '';

const getSubmitBasicInfoAndSurveyStatusAttempting = (state: HomeState): boolean => state.actions.submitBasicInfoAndSurveyStatus || false;
const getSubmitBasicInfoAndSurveyStatusError = (state: HomeState): string => state.error.submitBasicInfoAndSurveyStatus || '';

const getCommonCopiesAttempting = (state: HomeState): boolean => state.actions.commonCopies || false;
const getCommonCopiesError = (state: HomeState): string => state.error.commonCopies || '';
const getCommonCopies = (state: HomeState): ICommonCopies | undefined => state.commonCopies;

const getLanguagePackAttempting = (state: HomeState): boolean => state.actions.languagePack || false;
const getLanguagePackError = (state: HomeState): string => state.error.languagePack || '';
const getLanguagePack = (state: HomeState): ILanguagePack => state.languagePack || {};

const getScanInfoCanSkipUniqueCode = (state: HomeState): boolean => state.canSkipUniqueCode || false;
const getScanInfoCanSkipReward = (state: HomeState): boolean => state.canSkipReward || false;
const getScanInfoShowIsFirstTimeBuyerOption = (state: HomeState): boolean => state.showIsFirstTimeBuyerOption || false;
const getScanInfoPhoneNumberRepeated = (state: HomeState): boolean => state.phoneNumberRepeated || false;
const getScanInfoSurveyFlavor = (state: HomeState): string => state.surveyFlavor || '';

const getIsOTPScreenReady = (state: HomeState): boolean => state.displayOTPScreen || false;

const getSurveyName = (state:HomeState): string => state.surveyName || '';
const getSurveyQuestionsLength = (state: HomeState): number => state.numberOfQuestions || 1;

export default {
    getTrackScanAttempting,
    getTrackScanError,
    getScanId,
    getQrId,
    getQrType,

    getSelectLanguageAttempting,
    getSelectLanguageError,
    getSelectedLanguage,

    getSetDOBAttempting,
    getSetDOBError,
    getDob,

    getSetPhoneNumberAttempting,
    getSetPhoneNumberError,
    getPhoneNumber,

    getSubmitOTPAttempting,
    getSubmitOTPError,

    getResendOTPAttempting,
    getResendOTPError,

    getSubmitBasicInfoAttempting,
    getSubmitBasicInfoError,
    getIsTrialist,

    getScanInfoAttempting,
    getScanInfoError,
    getStage,

    getSelectLanguageAndDOBAttempting,
    getSelectLanguageAndDOBError,

    getSubmitUniqueCodeAndPhoneNumberAttempting,
    getSubmitUniqueCodeAndPhoneNumberError,

    getSubmitBasicInfoAndSurveyStatusAttempting,
    getSubmitBasicInfoAndSurveyStatusError,

    getCommonCopiesAttempting,
    getCommonCopiesError,
    getCommonCopies,

    getLanguagePackAttempting,
    getLanguagePackError,
    getLanguagePack,

    getScanInfoCanSkipUniqueCode,
    getScanInfoCanSkipReward,

    getScanInfoShowIsFirstTimeBuyerOption,

    getScanInfoPhoneNumberRepeated,
    getScanInfoSurveyFlavor,

    getTrackDOBAttempting,

    getIsOTPScreenReady,

    getSurveyName,
    getSurveyQuestionsLength,
};
