import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetRewardsResponse } from 'api/RewardsBase';
import { RewardsReduxState } from './types';

const initialState: RewardsReduxState = {
    actions: {
        getRewards: false,
    },
    code: '',
    expiry: '',
    value: 0,
    answeredYes: undefined,
    noRewardsLeft: false,
    url: '',
    error: {
        getRewards: '',
    },
};

interface RewardsError {
    errorMessage?: string;
    noRewardsLeft?: boolean;
}

const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        rewardsGetRewardsAttempt: (state) => {
            state.actions.getRewards = true;
            state.error.getRewards = '';
        },
        rewardsGetRewardsSuccess: (state, action: PayloadAction<GetRewardsResponse>) => {
            state.actions.getRewards = false;
            if (action.payload) {
                state.code = action.payload.code;
                state.expiry = action.payload.expiry;
                state.value = action.payload.value;
                state.answeredYes = action.payload.copyData?.answeredYes;
                state.url = action.payload.copyData?.url || '';
            }
        },
        rewardsGetRewardsFailure: (state, action: PayloadAction<RewardsError | undefined>) => {
            state.actions.getRewards = false;
            if (action.payload) {
                state.error.getRewards = action.payload.errorMessage || '';
                state.noRewardsLeft = action.payload.noRewardsLeft || false;
            }
        },
        rewardsResetRewards: (state) => {
            state.actions.getRewards = false;
            state.error.getRewards = '';
            state.code = '';
            state.expiry = '';
            state.value = 0;
            state.answeredYes = undefined;
            state.url = '';
        },
    },
});

export type RewardsState = typeof initialState;

export default {
    actions: rewardsSlice.actions,
    reducers: rewardsSlice.reducer,
};
