import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';
import { ISurvey } from 'entities/surveys';
import { AnswerSurveyParams, GetAllSurveysParams, GetSurveyFlavorParams, GetSurveyFlavorResponse, ISurveyGateway } from './SurveyBase';

const getHeaders = (scanId: string) => ({
    headers: {
        'X-Scan-Id': scanId,
    },
});

export default class SurveyGateway extends ISurveyGateway {
    async getAllSurveys(params: GetAllSurveysParams): GatewayResponse<ISurvey> {
        const response: ApiResponse<ISurvey> = await this.api.get('/c/survey', {}, getHeaders(params.scanId));
        return this.process(response);
    }

    async answerSurvey(params: AnswerSurveyParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('/c/answer', params.data, getHeaders(params.scanId));
        return this.process(response);
    }

    async getSurveyFlavor(params: GetSurveyFlavorParams): GatewayResponse<GetSurveyFlavorResponse> {
        const response: ApiResponse<GetSurveyFlavorResponse> = await this.api.get(`/qr/getSurveyFlavor/${params.id}`, {});
        return this.process(response);
    }
}
