import { IDataToSubmit, ISurvey } from 'entities/surveys';
import Gateway from './types/Gateway';
import { GatewayResponse } from './types/types';

export interface GetAllSurveysParams {
    scanId: string;
}

export interface AnswerSurveyParams {
    scanId: string;
    data: IDataToSubmit;
}

export interface GetSurveyFlavorParams {
    id: string;
}

export interface GetSurveyFlavorResponse {
    surveyFlavor: string;
}

export abstract class ISurveyGateway extends Gateway {
    abstract getAllSurveys(params: GetAllSurveysParams): GatewayResponse<ISurvey>;

    abstract answerSurvey(params: AnswerSurveyParams): GatewayResponse<null>;

    abstract getSurveyFlavor(params: GetSurveyFlavorParams): GatewayResponse<GetSurveyFlavorResponse>;
}
