import React, { FunctionComponent } from 'react';
import { FlattenSimpleInterpolation, css } from 'styled-components';
import Button from './Button';

export interface ButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    isSelected: boolean;
    children: React.ReactNode;
    style? : FlattenSimpleInterpolation;
}

const selected = css`
    background-color: #0096FF;
`;

const nonSelected = css`
    background-color: white;
`;

const ToggleButton: FunctionComponent<ButtonProps> = ({
    onClick,
    isSelected,
    children,
    style = undefined,
}: ButtonProps) => {
    return (
        <Button
            onClick={onClick}
            css={style ?? (isSelected ? selected : nonSelected)}
        >
            {children}
        </Button>
    );
};

ToggleButton.defaultProps = {
    isSelected: false,
    style: undefined,
};

export default ToggleButton;
