import React, { FunctionComponent } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Translate from 'i18n-js';

import { LanguageSelectionEnum } from 'api/HomeBase';

import Text from './Text';

interface LanguageSelectorProps {
    selected: LanguageSelectionEnum | undefined;
    onSelect: (lang: LanguageSelectionEnum) => void;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
    selected,
    onSelect,
}: LanguageSelectorProps) => {
    const countries = [
        {
            lang: LanguageSelectionEnum.Malay,
            id: 'malaysia',
            label: 'BM',
        },
        {
            lang: LanguageSelectionEnum.English,
            id: 'uk',
            label: 'EN',
        },
        {
            lang: LanguageSelectionEnum.Chinese,
            id: 'china',
            label: '中',
        },
    ];

    const renderCountries = () => {
        return countries.map((country) => {
            return (
                <Button type='button' style={{ color: 'white' }} onClick={() => onSelect(country.lang)} key={`flag-${country.id}`}>
                    {country.label}
                </Button>
            );
        });
    };

    return (
        <Container>
            <Row>
                {renderCountries()}
            </Row>
            <Text css={textStyle}>
                {Translate.t('selectLanguagePlaceholder')}
            </Text>
        </Container>
    );
};

export default LanguageSelector;

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const Container = styled.div`
    animation: ${fadeIn} 4s;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: absolute;
    right: 20px;
    top: 20px;
    justify-content: center;
`;

const Row = styled.div`
    display: flex;
    gap: .8rem;
    align-items: center;
`;

const Button = styled.button`
    all: unset;
`;

const textStyle = css`
    color: white;
    font-size: 0.7rem;
    text-align: center;
`;
