import OTPLOV from 'lib/LOVs/OTP';
import InactiveSurvey from 'lib/LOVs/InactiveSurvey';

export default {
    trackScanSurveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan Marlboro Vista.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan Marlboro yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the Marlboro Vista consumer feedback survey.

    This survey has ended, however do look out for future Marlboro consumer feedback surveys. Your reviews are important to us as we continue to improve.
    
    `,

    dobMessage1: 'By continuing to use this website, you confirm that you have read and accepted our',
    dobMessageTermsAndConditions: 'Terms & Conditions',
    dobMessageAnd: 'and',
    dobMessagePrivacyNotice: 'Privacy Notice',
    dobMessageFirstParagraphFullStop: '. ',
    dobMessage2: 'Enter your day of birth below to confirm that you are 19 years of age or above. By proceeding you are also confirming that you are a smoker and understand that the participation in this survey is voluntary.',
    dobDatepickerPlaceholder: 'YYYY',
    dobMessage3: 'Participants will receive RM5 as a token of appreciation for completing this Survey.',
    dobCancelButton: 'CANCEL',
    dobProceedButton: 'PROCEED',
    dobTermsAndConditionsModalHeader: 'Terms & Conditions',
    dobTermsAndConditionsModalOkButton: 'OK',
    dobPrivacyNoticeModalHeader: 'Privacy Notice',
    dobPrivacyNoticeModalOkButton: 'OK',

    uniqueCodeHeader: 'Unique Code',
    uniqueCodeMessage1: 'Please enter the unique code:',
    uniqueCodeInputLabel: 'UNIQUE CODE',
    uniqueCodeNextButton: 'NEXT',

    uniqueCodeErrorMessage1: 'Please enter unique code',
    uniqueCodeErrorMessage2: 'Invalid phone number',

    uniqueCodeUsedMessage1: 'Thank you for your interest in taking part in this survey.',
    uniqueCodeUsedMessage2: 'Unfortunately, this unique code have been used before.',
    uniqueCodeUsedMessage3: 'Kindly input a new unique code if you would like to participate this in survey.',

    phoneNumberMessage1: 'Hi, we are happy to see you’re interested in our survey.',
    phoneNumberMessage2: 'Please input your mobile number and survey unique code for us to send you an OTP.',
    phoneNumberMessage3: 'Please input your mobile number for us to send a verification code.',

    phoneNumberMobileNoInputLabel: 'Mobile No',
    phoneNumberSubmitButton: 'Submit',

    errorPhoneUsedMessage1: 'Sorry, you have answered this survey previously.',
    errorPhoneUsedMessage2: 'We thank you for your time.',

    otpHeader: 'OTP has been sent',
    otpMessage1: 'Please verify your number by keying in the verification code sent to your mobile number',
    otpCodeInputLabel: 'OTP',
    otpResendOtpButton: 'Resend OTP',
    otpResendOtpButtonSeconds: 's',
    otpNextButton: 'Submit',

    otpResendSuccess: 'OTP successfully resent!',
    optResendFailure: 'Resend failed. Please try again',
    otpResendFailure2: 'Please wait for at least 30 seconds before resending the OTP',

    otpSubmitError: 'Invalid OTP. Please request a new one.',

    basicInfoHeader: 'Let\'s get to know you',
    basicInfoGenderInputLabel: 'Gender',
    basicInfoGenderMale: 'Male',
    basicInfoGenderFemale: 'Female',
    basicInfoMobileNumberInputLabel: 'Mobile Number',
    basicInfoDOBInputLabel: 'Date Of Birth',
    basicInfoResidingStateInputLabel: 'State',
    basicInfoResidingStateJohor: 'Johor',
    basicInfoResidingStateKedah: 'Kedah',
    basicInfoResidingStateKelantan: 'Kelantan',
    basicInfoResidingStateMalacca: 'Melaka',
    basicInfoResidingStateNegeriSembilan: 'Negeri Sembilan',
    basicInfoResidingStatePahang: 'Pahang',
    basicInfoResidingStatePerak: 'Perak',
    basicInfoResidingStatePerlis: 'Perlis',
    basicInfoResidingStatePenang: 'Pulau Pinang',
    basicInfoResidingStateSabah: 'Sabah',
    basicInfoResidingStateSarawak: 'Sarawak',
    basicInfoResidingStateSelangor: 'Selangor',
    basicInfoResidingStateTerengganu: 'Terengganu',
    basicInfoResidingStateKualaLumpur: 'WP Kuala Lumpur',
    basicInfoResidingStateLabuan: 'WP Labuan',
    basicInfoResidingStatePutrajaya: 'WP Putrajaya',
    basicInfoEthnicityInputLabel: 'Ethnicity',
    basicInfoEthnicityMalay: 'Malay',
    basicInfoEthnicityChinese: 'Chinese',
    basicInfoEthnicityIndian: 'Indian',
    basicInfoEthnicityOthers: 'Others',
    basicInfoProfessionInputLabel: 'Do you work in any of these industry?',
    basicInfoProfessionAdvertisingJournalismPR: 'Advertising, Journalism, Public Relations',
    basicInfoProfessionGovernmentOfficerHealthcareTobacco: 'Government Officer, Healthcare, Tobacco',
    basicInfoProfessionMarketResearch: 'Market Research',
    basicInfoProfessionBlockedProfessions: 'Yes',
    basicInfoProfessionNotBlockedProfessions: 'No',
    basicInfoProfessionOtherProfessions: 'I do not work in any of the below professions',
    basicInfoProfessionBankingOrFinance: 'Banking/Finance',
    basicInfoProfessionManufacturing: 'Manufacturing',
    basicInfoProfessionTelecommunications: 'Telecommunications',
    basicInfoProfessionDistributionOrLogistic: 'Distribution/Logistic',
    basicInfoProfessionECommerceOrInformationTechnology: 'E-commerce/Information Technology',
    basicInfoProfessionMediaAndCommunicationsOrGovernmentOfficerOrHealthcare: 'Media & Communications/Government Officer/Healthcare',
    basicInfoProfessionMarketResearchOrPublicRelationsOrTobacco: 'Market Research/Public Relations/Tobacco',
    basicInfoProfessionSalesOrMarketing: 'Sales/Marketing',
    basicInfoProfessionOthers: 'I do not work in any of the below professions',
    basicInfoProfessionHospitalityOrFoodAndBeverage: 'Hospitality/F&B',
    basicInfoNicotineBrandInputLabel: 'What is the brand of cigarette you smoke most often?',
    basicInfoNicotineFlavorInputLabel: 'Which variant do you regularly smoke? ',
    basicInfoNicotineFlavorInputLabel2: 'What flavour do you regularly smoke? ',
    basicInfoSticksSmokedInputLabel: 'How many sticks of manufactured cigarettes do you smoke a week in the last 2 months?',
    basicInfoSticksSmokedLessThanOnePerWeek: 'Less than 1 sticks a week',
    basicInfoSticksSmokedMoreThanOnePerWeek: 'More than 1 sticks a week',
    basicInfoCurrentlyBreastfeedingInputLabel: 'Are you currently pregnant or breastfeeding?',
    basicInfoCurrentlyBreastfeedingYes: 'Yes',
    basicInfoCurrentlyBreastfeedingNo: 'No',
    basicInfoProceedButton: 'PROCEED',
    basicInfoErrorMessage: 'Please answer all questions',

    unqualifiedMessage1: `Thank you for taking part in the survey.

    Unfortunately, you do not meet the legal age required to participate in this survey.`,
    unqualifiedMessage2: 'Thank you for your time.',

    answerMultipleStatusHeader: 'Thank you! Let\'s get started with the survey.',
    answerMultipleMessage1: 'Is this your first time buying Bond Street Cigarillo?',
    answerMultipleYesButton: 'YES',
    answerMultipleNoButton: 'NO',

    surveySelectDropdown: 'Select ...',
    surveyFillAllSurveyErrorMessage: 'Please answer all questions',
    surveyShortFormAnswerErrorMessage: 'Please fill in the empty field',
    surveySubmitButton: 'Submit',

    rewardsCardTnGPin: 'TnG Pin',
    rewardsCardVoucherValueText: 'VOUCHER VALUE:',
    rewardsCardVoucherExpiryDateText: 'Voucher expiry date:',
    rewardsCopyAnsweredNoMessage1: 'Click',
    rewardsCopyAnsweredNoUrlLink: 'here',
    rewardsCopyAnsweredNoMessage2: 'to know more about a better alternative to smoking.',
    rewardsCopyAnsweredYesMessage1: 'Click',
    rewardsCopyAnsweredYesUrlLink: 'here',
    rewardsCopyAnsweredYesMessage2: 'to know more about IQOS and lil.',

    rewardsNoRewardsLeftMessage1: 'Due to overwhelming responses, our allocated RM2 vouchers have been fully redeemed.',

    generalError: 'Sorry! Something went wrong. Please try again.',
    rewardAlreadyAwardedError: 'Already awarded',

    languageSelectionAndDOBHeaderContainerTitle: 'Age Verification',
    languageSelectionAndDOBHeaderContainerSubTitle: 'Please enter your date of birth below to confirm that you are 19 years of age or above.',
    languageSelectionAndDOBheaderContainerSubTitle2: 'Your participation is voluntary and all responses are kept confidential and strictly for research purposes.',

    languageSelectionAndDOBHeaderContainerDatePick: 'SELECT YOUR BIRTH DATE',
    languageSelectionAndDOBHeaderContainerMessage1: 'I hereby declare and confirm that:',
    languageSelectionAndDOBHeaderContainerMessage2: 'I hereby further declare and confirm that I do not currently work in any of the following industries:',
    languageSelectionAndDOBHeaderContainerContent1: 'I am 19 years old or above',
    languageSelectionAndDOBHeaderContainerContent2: 'I am currently not pregnant nor breastfeeding',
    languageSelectionAndDOBHeaderContainerContent3: 'I have read and agree with the',
    languageSelectionAndDOBHeaderContainerContent4: 'I have read and consent to the',
    languageSelectionAndDOBHeaderContainerContent5: 'I have smoked more than 1 stick a week in the last 2 months',
    languageSelectionAndDOBHeaderContainerContent6: 'I have smoked more than 1 stick a week in the last 2 months',
    languageSelectionAndDOBHeaderContainerContent7: 'Advertising, Journalism, Public Relations, Market Research',
    languageSelectionAndDOBHeaderContainerContent8: 'Government Officer, Law, Healthcare, Tobacco',
    languageSelectionAndDOBHeaderContainerContent9: 'Market Research',
    languageSelectionAndDOBHeaderContainerTermsAndConditions: 'Terms Of Use',
    languageSelectionAndDOBHeaderContainerPrivacyNotice: 'Privacy Notice',
    languageSelectionAndDOBHeaderContainerAnd: ' and',
    languageSelectionAndDOBHeaderContainerDisclaimer: 'Disclaimer',
    languageSelectionAndDOBHeaderContainerFullStop: '.',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderMonth: 'Month',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth1: 'January',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth2: 'February',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth3: 'March',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth4: 'April',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth5: 'May',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth6: 'June',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth7: 'July',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth8: 'August',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth9: 'September',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth10: 'October',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth11: 'November',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth12: 'December',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderYear: 'Year',
    languageSelectionAndDOBSubmitButton: 'SUBMIT',
    languageSelectionAndDOBTermsAndConditionsModalHeader: 'Terms Of Use',
    languageSelectionAndDOBTermsAndConditionsModalOkButton: 'OK',
    languageSelectionAndDOBPrivacyNoticeModalHeader: 'Privacy Notice',
    languageSelectionAndDOBPrivacyNoticeModalOkButton: 'OK',
    languageSelectionAndDOBDisclaimerModalHeader: 'Disclaimer',
    languageSelectionAndDOBDisclaimerModalOkButton: 'OK',

    skipRewardTermsAndConditionTitle: 'Eligibility',
    skipRewardTermsAndConditionContent1: 'This survey is only open to legal aged smokers who:',
    skipRewardTermsAndConditionContent2: 'Are 19 years or above.',
    skipRewardTermsAndConditionContent3: 'Currently not pregnant nor breastfeeding.',
    skipRewardTermsAndConditionContent4: 'Have smoked more than 1 stick a week in the past 2 months.',
    skipRewardTermsAndConditionContent5: 'This survey will be carried out for our data gathering purposes on a first-come-first-serve basis.',

    skipRewardDisclaimerContent1: 'This Survey is not in any way intended to promote smoking and/or using of a cigarette product/brand or other smoke products in general. Please do not discuss this Survey on any of your social media or messaging platform.',
    skipRewardDisclaimerContent2: 'This Survey is being conducted for internal use only and is aimed at gathering feedback for purposes such as customer satisfaction assessment, market research, brand perception analysis, product improvement, and other internal research initiatives. As described in the Privacy Notice, the feedback that we have gathered will be shared and disclosed to Philip Morris (Malaysia) Sdn Bhd solely for the abovementioned purposes. Your participation is voluntary and all responses will be kept confidential and limited to use, for example, solely for the aforementioned purposes.',
    skipRewardDisclaimerContent3: 'Smoking is dangerous and addictive. Currently there is no such thing as a safe cigarette, nor can it be assumed that any cigarette brands variant or product format is safer than any other. The purpose of this Survey is not to address smoking and health related issues nor issues related to smoking initiation, quitting or attempts to quit.',

    skipRewardPrivacyNoticeTitle1: 'Introduction',
    skipRewardPrivacyNoticeTitle2: 'Information collection',
    skipRewardPrivacyNoticeTitle3: 'Information use',
    skipRewardPrivacyNoticeTitle4: 'Disclosure of information to third parties',
    skipRewardPrivacyNoticeTitle5: 'Information retention',
    skipRewardPrivacyNoticeTitle6: 'Your personal information rights',
    skipRewardPrivacyNoticeTitle7: 'Information security',
    skipRewardPrivacyNoticeTitle8: 'Changes to our Privacy Notice',
    skipRewardPrivacyNoticeTitle9: 'Contact information',

    skipRewardPrivacyNoticeContent1: 'This Privacy Notice describes how we, us, Bike Bear Sdn Bhd collect, use, and share information gathered in connection with your completion of any survey, focus group, interview, study, or other activity designed to collect information that is conducted by Bike Bear Sdn Bhd collectively, “Surveys”) that links to or references this Privacy Notice. By responding to one of these Surveys, you agree to the use of such information in accordance with this Privacy Notice.',
    skipRewardPrivacyNoticeContent2: 'We may collect personal information about you that you provide as part of completing a Survey such as:',
    skipRewardPrivacyNoticeContent2subContent1: 'Residing State',
    skipRewardPrivacyNoticeContent2subContent2: 'Ethnicity',
    skipRewardPrivacyNoticeContent2subContent3: 'Telephone number',
    skipRewardPrivacyNoticeContent2subContent4: 'Date of birth',
    skipRewardPrivacyNoticeContent2subContent5: 'Any other information about you that is relevant to the survey',
    skipRewardPrivacyNoticeContent3: 'We use your personal information that we collect in connection with a Survey for the purposes described in the Survey, or to assist with decision making in ours or our clients\' products and services, or where we have a legitimate business interest in or other legal basis for, such collection and use. The personal information you submit to us may also be used to verify your identity when you access a Survey, manage our relationship with you, including any of your requests, or to customize or improve our Surveys.',
    skipRewardPrivacyNoticeContent4: 'We may also contact you to seek feedback on services provided by us or our affiliate for research purposes. Your personal information may also be used to protect our rights or property and that of our users.',
    skipRewardPrivacyNoticeContent17: 'Please note that it will be necessary for us to process your Personal Data for these purposes, without which you will not be able to take part in this Survey.',
    skipRewardPrivacyNoticeContent5: 'We may provide your personal information to our affiliate in connection with the performance of the Survey, including analysis of the data collected in the Survey. Your personal information may also be disclosed to other third parties, including Philip Morris (Malaysia) Sdn Bhd, our client, to assist them in making decisions about their products and services.',
    skipRewardPrivacyNoticeContent6: 'Personal information may also be disclosed to law enforcement, regulatory or other government agencies, or to other third parties, in each case to comply with legal, regulatory, or national security obligations or requests.',
    skipRewardPrivacyNoticeContent7: 'We will provide notice and obtain your consent before sharing personal information about you with an unaffiliated third party that is not performing services for us, except if such sharing is otherwise permitted by this Privacy Notice or the Survey.',
    skipRewardPrivacyNoticeContent8: 'All these disclosures may involve the transfer of personal information to countries or regions without data protection rules like those in effect in your area of residence.',
    skipRewardPrivacyNoticeContent9: 'Any personal information that we have referenced above under “Information collection” may be disclosed to the third parties identified in this section for the purposes set forth herein.',
    skipRewardPrivacyNoticeContent10: 'We retain personal information as reasonably necessary for the duration of the purposes outlined in this Privacy Notice. Notwithstanding the duration of the purposes, we may retain your information if necessary to comply with our legal or professional obligations, enforce our agreements, or resolve disputes.',
    skipRewardPrivacyNoticeContent11: 'You may have choices about how your personal information is collected, used and shared.',
    skipRewardPrivacyNoticeContent12: 'You may have the right to request that we confirm whether we process your personal information or that we correct, delete, or stop processing your personal information. You may also have the right to request that we provide the following information regarding the personal information we hold about you:',
    skipRewardPrivacyNoticeContent12SubContent1: 'The categories and/or specific pieces of personal information we collected',
    skipRewardPrivacyNoticeContent12SubContent2: 'The categories of sources from which personal information is collected',
    skipRewardPrivacyNoticeContent12SubContent3: 'The business or commercial purpose for collecting personal information',
    skipRewardPrivacyNoticeContent12SubContent4: 'The categories of third parties with whom we shared personal information',
    skipRewardPrivacyNoticeContent13: 'To exercise any of your rights under applicable law described above regarding your personal information, please call us at this number 1-800-88-0033. When calling us, please provide your full name, mobile number and the specific type of request you are making.',
    skipRewardPrivacyNoticeContent14: 'We have in place reasonable commercial standards of technology and operational security to protect all personal information provided by users with respect to the Surveys from unauthorized access, disclosure, alteration or destruction.',
    skipRewardPrivacyNoticeContent15: 'We may modify or amend this Privacy Notice from time to time at our discretion. When we make changes to this Privacy Notice, we will amend the revision date at the top of this page and such modified or amended Privacy Notice shall be effective as to you and your information as of that revision date. We encourage you to periodically review this Privacy Notice to view any updates.',
    skipRewardPrivacyNoticeContent16: 'To exercise any of your rights under applicable described above regarding your personal information, or if you have questions or concerns regarding your privacy in connection with a Survey, please contact us at +6011-5691-8469. When calling us, please provide your full name, mobile number and the specific type of request that you are making.',

    uniqueCodeAndPhoneNumberMessage1: 'Hi, we are happy to see you\'re interested in our survey.',
    uniqueCodeAndPhoneNumberMessage2: 'Please input the survey code found on your pack sleeve and your mobile number for us to send a verification code.',
    uniqueCodeAndPhoneNumberHowToFindUniqueCode: 'LOOK FOR YOUR UNIQUE CODE INSIDE THE SLEEVE',

    uniqueCodeAndPhoneNumberStep1: 'STEP 1',
    uniqueCodeAndPhoneNumberStep1Message: 'Remove the sticker at the side of the pack sleeve',

    uniqueCodeAndPhoneNumberStep2: 'STEP 2',
    uniqueCodeAndPhoneNumberStep2Message: 'Remove the pack sleeve and check inside the sleeve for the Unique Code',

    uniqueCodeAndPhoneNumberError1: 'Please enter unique code.',
    uniqueCodeAndPhoneNumberError2: 'Invalid phone number.',

    uniqueCodeAndPhoneNumberErrorBlocked: `Thank you for taking part in the survey. 
    We value your opinion.
    
    Unfortunately, you have exceeded the limit of responses required.
    
    Thank you for your time and effort.`,

    bondOtpMessage1: `Please input your OTP sent to +${OTPLOV.phoneNumber}`,

    bondBasicInfoFirstTimeBuyerInputLabel: 'Is this your first time buying Bond Street Cigarillo?',
    bondBasicInfoFirstTimeBuyerInputLabelSS: 'Is this your first time buying Marlboro Summer Splash?',
    bondBasicInfoFirstTimeBuyerInputLabelTS: 'Is this your first time buying Marlboro Tropical Splash?',
    bondBasicInfoFirstTimeBuyerInputLabelGeneric: 'Is this your first time buying',
    bondBasicInfoFirstTimeBuyerYes: 'Yes',
    bondBasicInfoFirstTimeBuyerNo: 'No',

    selectLanguagePlaceholder: 'Select language',

    invalidUniqueCodeError: 'This unique code have been used before. Kindly input a new unique code if you would like to continue.',

    languageSelectionAndDOBHeaderContainerAgeRange: 'Please state your age',

    surveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan ${InactiveSurvey.mainTitle}.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan ${InactiveSurvey.subTitle} yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the ${InactiveSurvey.mainTitle} consumer feedback survey.

    This survey has ended, however do look out for future ${InactiveSurvey.subTitle} consumer feedback surveys. Your reviews are important to us as we continue to improve.
    
    `,
    surveyNextText: 'Next',
    surveyBackText: 'Back',
    surveyQuestionNoAnswerToast: 'Please answer this question',

    languageSelectionAndDOBAgeError: 'Sorry, you need to be at least 19 years old to participate in this survey.',
    noDOBAgeSelectedError: 'Please select your age before submitting.',

    languageSelectionAndDOBAgreementText: 'I have read and agree with the {terms}, {disclaimer} and consent to the {privacyNotice}',

    OTPrequest: 'Request for OTP',

    goBackPromptMessage: 'Are you sure you want to go back? You will lose your progress.',
    goBackToOtpPromptMessage: 'You will have to key in your phone number again later to continue.',

    otherBrandsLabel: 'Others',
    surveyTitle: 'Survey Questions',

    inactivityModalTitle: 'Attention',
    inactivityModalQuestion: 'Are you still using this page?',
    inactivityModalYesButton: 'Yes',

    surveyQuestionsDisclaimerTerms: '* The information contained herein is confidential. It is solely intended for research study and for internal use only.  It is not related to sales or promotional activities in any form. Any reproduction of the same or any part thereof is strictly prohibited.',

    selectOneWithShortAnswerTitle: 'Other tobacco brands products (please state)',

    clickNextToProceed: 'Click Next to proceed',
    clickSubmitToProceed: 'Click Submit to proceed',
};
