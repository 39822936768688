import authSlice from 'redux/slices/auth';
import homeSlice from 'redux/slices/home';
import rewardsSlice from 'redux/slices/rewards';
import errorSlice from 'redux/slices/error';
import surveySlice from 'redux/slices/survey';

export default {
    ...authSlice.actions,
    ...homeSlice.actions,
    ...rewardsSlice.actions,
    ...errorSlice.actions,
    ...surveySlice.actions,
};
