import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import RewardsGateway from 'api/Rewards';
import Translate from 'translate/Translate';

import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';

export default function* watchGetRewards(api: RewardsGateway): SagaWatcherReturnType {
    yield takeEvery('rewards/rewardsGetRewardsAttempt', handleGetRewards, api);
}

function* handleGetRewards(api: RewardsGateway) {
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.rewardsGetRewardsFailure({ errorMessage: '' }));
        yield put(Actions.errorSetGeneralErrorPageMessage('Something went wrong. Please try again'));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.getRewards], { scanId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.rewardsGetRewardsFailure({
            errorMessage: response.message || 'Something went wrong. Please try again',
        }));

        if (response.name === '1705') {
            yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, Translate.t('rewardAlreadyAwardedError') || 'Something went wrong please try again')));
            NavActions.navToError();
            return;
        }

        if (response.name === '1704') {
            yield put(Actions.rewardsGetRewardsFailure({
                noRewardsLeft: true,
            }));

            return;
        }

        yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name || '', response.message || 'Something went wrong please try again')));
        NavActions.navToError();
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(Actions.rewardsGetRewardsSuccess(response.data));
        }
    }
}
