import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledGrid = styled.div<{isOnlyOne: boolean}>`
    background-color: transparent;

    display: flex;

    justify-content: center;
    align-items: center;

    margin-bottom: 20px;

    width: 100%;
    height: fit-content;

    grid-template-columns: 120px 120px;

    gap: 20px;

    flex-wrap: wrap;
    padding: 10px;

    #img {
        height: 120px;
        width: ${props => (props.isOnlyOne ? '100%' : '120px')};
        object-fit: contain;

        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }
`;

interface StyledGridProp {
    isOnlyOne: boolean;
}

const Grid: FunctionComponent<StyledGridProp> = ({ isOnlyOne, children }) => {
    return (
        <StyledGrid
            isOnlyOne={isOnlyOne}
        >
            {children}
        </StyledGrid>
    );
};

export default Grid;
