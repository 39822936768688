import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { ITrackDOBParams } from 'redux/slices/home/types';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchTrackDOB(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeTrackDOBAttempt', handleTrackDOB, api);
}

function* handleTrackDOB(api: HomeGateway, data: PayloadAction<ITrackDOBParams>) {
    const { dob } = data.payload;

    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeTrackDOBFailure());

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.trackDOB], { scanId, dob });

    if (response.status === GatewayResponseStatus.Error) {
        // Error is stored for debugging purposes, we won't show the error to the user
        yield put(Actions.homeTrackDOBFailure());

        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeTrackDOBSuccess());
    }
}
