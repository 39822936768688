import OTPLOV from 'lib/LOVs/OTP';
import InactiveSurvey from 'lib/LOVs/InactiveSurvey';

export default {
    trackScanSurveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan Marlboro Vista.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan Marlboro yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the Marlboro Vista consumer feedback survey.

    This survey has ended, however do look out for future Marlboro consumer feedback surveys. Your reviews are important to us as we continue to improve.

    `,

    dobMessage1: 'Dengan menggunakan laman web ini, anda mengesahkan bahawa anda telah membaca dan menerima',
    dobMessageTermsAndConditions: 'Terma Penggunaan',
    dobMessageAnd: 'dan',
    dobMessagePrivacyNotice: 'Notis Privasi',
    dobMessageFirstParagraphFullStop: 'kami. ',
    dobMessage2: 'Sila masukkan tarikh lahir anda di bawah untuk mengesahkan bahawa anda berumur 19 tahun atau ke atas. Dengan meneruskan tinjauan ini, anda juga mengesahkan bahawa anda adalah perokok dan memahami bahawa penyertaan anda dalam tinjauan ini adalah secara sukarela.',
    dobDatepickerPlaceholder: 'YYYY',
    dobMessage3: 'Peserta akan menerima RM5 sebagai tanda penghargaan kerana melengkapkan tinjauan ini.​',
    dobCancelButton: 'BATAL',
    dobProceedButton: 'TERUSKAN',
    dobTermsAndConditionsModalHeader: 'Terms & Conditions',
    dobTermsAndConditionsModalOkButton: 'TERUSKAN',
    dobPrivacyNoticeModalHeader: 'Privacy Notice',
    dobPrivacyNoticeModalOkButton: 'TERUSKAN',

    uniqueCodeHeader: 'Kod Unik',
    uniqueCodeMessage1: 'Please enter the unique code:',
    uniqueCodeInputLabel: 'Kod Unik',
    uniqueCodeNextButton: 'NEXT',

    uniqueCodeErrorMessage1: 'Sila masukkan kod unik',
    uniqueCodeErrorMessage2: 'Nombor telefon tidak sah',

    uniqueCodeUsedMessage1: 'Terima kasih terhadap minat anda untuk turut serta dalam tinjauan ini.',
    uniqueCodeUsedMessage2: 'Namun, kod unik ini telah digunakan.',
    uniqueCodeUsedMessage3: 'Sila masukkan kod unik baharu sekiranya anda ingin turut serta dalam tinjauan ini.',

    phoneNumberMessage1: 'Hai, kami gembira melihat anda berminat dalam tinjauan kami.',
    phoneNumberMessage2: 'Sila masukkan nombor telefon anda dan kod unik tinjauan untuk menerima OTP daripada kami.',
    phoneNumberMessage3: 'Sila masukkan nombor telefon kepada kami untuk pengesahan kod.',

    phoneNumberMobileNoInputLabel: 'Nombor Telefon',
    phoneNumberSubmitButton: 'Hantar',

    errorPhoneUsedMessage1: 'Maaf, anda telah menjawab tinjauan ini.',
    errorPhoneUsedMessage2: 'Terima kasih untuk masa anda.',

    otpHeader: 'OTP telah dihantar',
    otpMessage1: 'Sila sahkan nombor anda dengan memasukkan kod pengesahan yang telah dihantar kepada nombor telefon anda',
    otpCodeInputLabel: 'OTP',
    otpResendOtpButton: 'Hantar semula OTP',
    otpResendOtpButtonSeconds: 's',
    otpNextButton: 'Hantar',

    otpResendSuccess: 'OTP berjaya dihantar semula!',
    optResendFailure: 'Hantar semula gagal. Sila cuba lagi.',
    otpResendFailure2: 'Sila tunggu sekurang-kurangnya 30 saat sebelum menghantar semula OTP',

    otpSubmitError: 'OTP tidak sah. Sila minta yang baharu.',

    basicInfoHeader: 'Mari mengenali anda',
    basicInfoGenderInputLabel: 'Jantina',
    basicInfoGenderMale: 'Lelaki',
    basicInfoGenderFemale: 'Perempuan',
    basicInfoMobileNumberInputLabel: 'Nombor Telefon',
    basicInfoDOBInputLabel: 'Tarikh Lahir',
    basicInfoResidingStateInputLabel: 'Negeri Tempat Tinggal',
    basicInfoResidingStateJohor: 'Johor',
    basicInfoResidingStateKedah: 'Kedah',
    basicInfoResidingStateKelantan: 'Kelantan',
    basicInfoResidingStateMalacca: 'Melaka',
    basicInfoResidingStateNegeriSembilan: 'Negeri Sembilan',
    basicInfoResidingStatePahang: 'Pahang',
    basicInfoResidingStatePerak: 'Perak',
    basicInfoResidingStatePerlis: 'Perlis',
    basicInfoResidingStatePenang: 'Pulau Pinang',
    basicInfoResidingStateSabah: 'Sabah',
    basicInfoResidingStateSarawak: 'Sarawak',
    basicInfoResidingStateSelangor: 'Selangor',
    basicInfoResidingStateTerengganu: 'Terengganu',
    basicInfoResidingStateKualaLumpur: 'WP Kuala Lumpur',
    basicInfoResidingStateLabuan: 'WP Labuan',
    basicInfoResidingStatePutrajaya: 'WP Putrajaya',
    basicInfoEthnicityInputLabel: 'Etnik',
    basicInfoEthnicityMalay: 'Melayu',
    basicInfoEthnicityChinese: 'Cina',
    basicInfoEthnicityIndian: 'India',
    basicInfoEthnicityOthers: 'Lain-lain',
    basicInfoProfessionInputLabel: 'Adakah anda berkerja dalam industri yang berikut?',
    basicInfoProfessionAdvertisingJournalismPR: 'Pengiklanan, Kewartawanan, Perhubungan Awam',
    basicInfoProfessionGovernmentOfficerHealthcareTobacco: 'Pegawai Kerajaan, Kesihatan, Tembakau',
    basicInfoProfessionMarketResearch: 'Penyelidikan Pemasaran',
    basicInfoProfessionOtherProfessions: 'Saya tidak bekerja dalam mana-mana profesion di bawah',
    basicInfoProfessionBlockedProfessions: 'Ya',
    basicInfoProfessionNotBlockedProfessions: 'Tidak',
    basicInfoProfessionBankingOrFinance: 'Perbankan/Kewangan',
    basicInfoProfessionManufacturing: 'Pembuatan',
    basicInfoProfessionTelecommunications: 'Telekomunikasi',
    basicInfoProfessionDistributionOrLogistic: 'Pengedaran/Logistik',
    basicInfoProfessionECommerceOrInformationTechnology: 'E-Dagang/Teknologi Maklumat',
    basicInfoProfessionMediaAndCommunicationsOrGovernmentOfficerOrHealthcare: 'Media & Komunikasi/Pegawai Kerajaan/Profesional Perubatan',
    basicInfoProfessionMarketResearchOrPublicRelationsOrTobacco: 'Penyelidikan Pasaran/Perhubungan Awam/Industri Tembakau',
    basicInfoProfessionSalesOrMarketing: 'Penjualan/Pemasaran',
    basicInfoProfessionOthers: 'Tiada di atas',
    basicInfoProfessionHospitalityOrFoodAndBeverage: 'Hospitaliti/F&B',
    basicInfoNicotineBrandInputLabel: 'Apakah jenama rokok yang anda paling kerap guna?',
    basicInfoNicotineFlavorInputLabel: 'Apakah varian yang anda kerap hisap?',
    basicInfoNicotineFlavorInputLabel2: 'Apakah varian yang anda kerap hisap? ',
    basicInfoSticksSmokedInputLabel: 'Berapa banyak rokok dikilangkan yang anda hisap dalam masa seminggu untuk 2 bulan yang lepas?',
    basicInfoSticksSmokedLessThanOnePerWeek: 'Kurang daripada 1 batang rokok seminggu',
    basicInfoSticksSmokedMoreThanOnePerWeek: 'Lebih daripada 1 batang rokok seminggu',
    basicInfoCurrentlyBreastfeedingInputLabel: 'Adakah anda hamil atau sedang menyusu?',
    basicInfoCurrentlyBreastfeedingYes: 'Ya',
    basicInfoCurrentlyBreastfeedingNo: 'Tidak',
    basicInfoProceedButton: 'HANTAR',
    basicInfoErrorMessage: 'Sila jawab semua soalan',

    unqualifiedMessage1: 'Harap maaf, anda tidak layak untuk turut serta dalam tinjauan ini.',
    unqualifiedMessage2: 'Terima kasih untuk masa anda.',

    answerMultipleStatusHeader: 'Thank you! Let\'s get started with the survey.',
    answerMultipleMessage1: 'Is this your first time buying Bond Street Cigarillo?',
    answerMultipleYesButton: 'YES',
    answerMultipleNoButton: 'NO',

    surveySelectDropdown: 'Pilih ...',
    surveyFillAllSurveyErrorMessage: 'Sila jawab semua soalan',
    surveyShortFormAnswerErrorMessage: 'Sila lengkapkan ruangan yang kosong',
    surveySubmitButton: 'Hantar',

    rewardsCardTnGPin: 'Pin TnG',
    rewardsCardVoucherValueText: 'NILAI BAUCAR:',
    rewardsCardVoucherExpiryDateText: 'Tarikh luput baucar:',
    rewardsCopyAnsweredNoMessage1: 'Tekan',
    rewardsCopyAnsweredNoUrlLink: 'sini',
    rewardsCopyAnsweredNoMessage2: 'untuk mengetahui dengan lebih lanjut mengenai alternatif yang lebih baik untuk merokok.',
    rewardsCopyAnsweredYesMessage1: 'Tekan',
    rewardsCopyAnsweredYesUrlLink: 'sini',
    rewardsCopyAnsweredYesMessage2: 'untuk mengetahui dengan lebih lanjut mengenai IQOS dan lil.',

    rewardsNoRewardsLeftMessage1: 'Disebabkan sambutan yang menggalakkan, baucar RM2 yang diperuntukkan telah ditebus sepenuhnya.',

    generalError: 'Maaf! Masalah tidak diketahui telah berlaku. Sila cuba semula.',
    rewardAlreadyAwardedError: 'Ganjaran sudah diberi.',

    languageSelectionAndDOBHeaderContainerTitle: 'Pengesahan umur',
    languageSelectionAndDOBHeaderContainerSubTitle: 'Masukkan tarikh lahir anda di bawah untuk mengesahkan bahawa anda berumur 19 tahun atau lebih.',
    languageSelectionAndDOBheaderContainerSubTitle2: 'Penyertaan anda adalah secara sukarela dan semua maklum balas adalah rahsia dan hanya untuk tujuan penyelidikan.',

    languageSelectionAndDOBHeaderContainerDatePick: 'PILIH TARIKH LAHIR',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderMonth: 'Bulan',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth1: 'Januari',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth2: 'Februari',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth3: 'Mac',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth4: 'April',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth5: 'Mei',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth6: 'Jun',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth7: 'Julai',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth8: 'Ogos',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth9: 'September',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth10: 'Oktober',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth11: 'November',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth12: 'Disember',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderYear: 'Tahun',
    languageSelectionAndDOBHeaderContainerMessage1: 'Saya dengan ini mengisytiharkan dan mengesahkan bahawa:',
    languageSelectionAndDOBHeaderContainerMessage2: 'Saya juga dengan ini mengisytiharkan dan mengesahkan bahawa saya tidak bekerja dalam industri berikut pada masa sekarang:',
    languageSelectionAndDOBHeaderContainerContent1: 'Saya berumur 19 tahun atau lebih',
    languageSelectionAndDOBHeaderContainerContent2: 'Saya tidak hamil dan tidak menyusu pada masa sekarang.',
    languageSelectionAndDOBHeaderContainerContent3: 'Saya telah membaca dan setuju dengan',
    languageSelectionAndDOBHeaderContainerContent4: 'Saya telah membaca dan membenarkan',
    languageSelectionAndDOBHeaderContainerContent5: 'Saya merokok lebih daripada 1 batang seminggu dalam tempoh 2 bulan yang lalu',
    languageSelectionAndDOBHeaderContainerContent6: 'Saya telah merokok lebih daripada 1 batang seminggu dalam tempoh 2 bulan yang lepas.',
    languageSelectionAndDOBHeaderContainerContent7: 'Pengiklanan, Kewartawanan, Perhubungan Awam, Penyelidikan Pasaran.',
    languageSelectionAndDOBHeaderContainerContent8: 'Pegawai kerajaan, Undang-undang, Penjagaan Kesihatan, Tembakau.',
    languageSelectionAndDOBHeaderContainerContent9: 'Penyelidikan Pemasaran',
    languageSelectionAndDOBHeaderContainerTermsAndConditions: 'Syarat Penggunaan',
    languageSelectionAndDOBHeaderContainerPrivacyNotice: 'Notis Privasi',
    languageSelectionAndDOBHeaderContainerAnd: ' dan',
    languageSelectionAndDOBHeaderContainerDisclaimer: 'Penafian',
    languageSelectionAndDOBHeaderContainerFullStop: ' kami.',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholder: 'YYYY',
    languageSelectionAndDOBSubmitButton: 'Hantar',
    languageSelectionAndDOBTermsAndConditionsModalHeader: 'Terms Of Use [Syarat Penggunaan]',
    languageSelectionAndDOBTermsAndConditionsModalOkButton: 'OK',
    languageSelectionAndDOBPrivacyNoticeModalHeader: 'Privacy Notice [Notis Privasi]',
    languageSelectionAndDOBPrivacyNoticeModalOkButton: 'OK',
    languageSelectionAndDOBDisclaimerModalHeader: 'Disclaimer [Penafian]',
    languageSelectionAndDOBDisclaimerModalOkButton: 'OK',

    skipRewardTermsAndConditionTitle: 'Kelayakan',
    skipRewardTermsAndConditionContent1: 'Tinjauan ini hanya terbuka kepada perokok berumur sah yang:',
    skipRewardTermsAndConditionContent2: 'Berumur 19 tahun ke atas.',
    skipRewardTermsAndConditionContent3: 'Tidak mengandung dan tidak menyusu pada masa sekarang.',
    skipRewardTermsAndConditionContent4: 'Telah merokok lebih daripada 1 batang seminggu dalam tempoh 2 bulan yang lepas.',
    skipRewardTermsAndConditionContent5: 'Tinjauan ini akan dijalankan untuk tujuan pengumpulan data atas dasar siapa cepat dia dapat.',

    skipRewardDisclaimerContent1: 'Tinjauan ini tidak bertujuan sama-sekali untuk mempromosikan merokok dan/atau penggunaan produk/jenama rokok atau mana-mana produk asap lain. Pastikan anda tidak bincangkan tinjauan ini di atas mana-mana media sosial atau platform mesej anda.',
    skipRewardDisclaimerContent2: 'Tinjauan ini dijalankan untuk kegunaan dalaman sahaja bagi mengumpul maklum balas untuk tujuan seperti penilaian kepuasan pelanggan, penyelidikan pasaran, analisis persepsi jenama, penambahbaikan produk dan inisiatif penyelidikan dalaman yang lain. Seperti yang diterangkan dalam Notis Privasi, maklum balas yang kami kumpulkan akan dikongsi dan didedahkan kepada Philip Morris (Malaysia) Sdn Bhd untuk tujuan yang dinyatakan di atas sahaja. Penyertaan anda adalah secara sukarela dan semua jawapan akan dirahsiakan dan terhad untuk digunakan. Sebagai contoh, ia semata-mata untuk tujuan seperti yang dinyatakan di atas.',
    skipRewardDisclaimerContent3: 'Merokok adalah aktiviti berbahaya dan boleh menyebabkan ketagihan. Buat masa ini, produk rokok yang selamat tidak wujud, dan mana-mana varian jenama rokok atau format produk rokok tidak boleh diandaikan sebagai lebih selamat daripada yang lain. Tujuan tinjauan ini bukan untuk menangani isu-isu berkaitan merokok dan kesihatan, mahupun isu yang berkaitan dengan permulaan merokok, penghentian, atau percubaan untuk berhenti merokok.',

    skipRewardPrivacyNoticeTitle1: 'Pengenalan',
    skipRewardPrivacyNoticeTitle2: 'Pengumpulan maklumat',
    skipRewardPrivacyNoticeTitle3: 'Kegunaan maklumat',
    skipRewardPrivacyNoticeTitle4: 'Pendedahan maklumat kepada pihak ketiga',
    skipRewardPrivacyNoticeTitle5: 'Penyimpanan maklumat',
    skipRewardPrivacyNoticeTitle6: 'Hak maklumat peribadi anda',
    skipRewardPrivacyNoticeTitle7: 'Keselamatan maklumat',
    skipRewardPrivacyNoticeTitle8: 'Perubahan pada Notis Privasi kami',
    skipRewardPrivacyNoticeTitle9: 'Maklumat perhubungan',

    skipRewardPrivacyNoticeContent1: 'Notis Privasi ini menerangkan cara kami, Bike Bear Sdn Bhd, mengumpulkan, menggunakan dan berkongsi maklumat yang dikumpul berkaitan dengan jawapan anda bagi sebarang tinjauan, kumpulan fokus, temu bual, kajian atau aktiviti lain yang direka untuk mengumpul maklumat, seperti yang dijalankan oleh Bike Bear Sdn Bhd (dinyatakan secara kolektif sebagai “Tinjauan”) yang memautkan atau merujuk Notis Privasi ini. Dengan menjawab salah satu daripada Tinjauan ini, anda bersetuju atas penggunaan maklumat tersebut berdasarkan terma dalam Notis Privasi ini.',
    skipRewardPrivacyNoticeContent2: 'Kami mungkin akan mengumpul maklumat peribadi tentang anda yang anda berikan dalam melengkapkan Tinjauan seperti:',
    skipRewardPrivacyNoticeContent2subContent1: 'Negeri mastautin',
    skipRewardPrivacyNoticeContent2subContent2: 'Bangsa',
    skipRewardPrivacyNoticeContent2subContent3: 'Nombor Telefon',
    skipRewardPrivacyNoticeContent2subContent4: 'Tarikh lahir',
    skipRewardPrivacyNoticeContent2subContent5: 'Lain-lain maklumat tentang anda yang mungkin relevan kepada tinjauan berkenaan',
    skipRewardPrivacyNoticeContent3: 'Kami menggunakan maklumat peribadi anda yang dikumpulkan daripada Tinjauan untuk tujuan yang diterangkan dalam Tinjauan berkenaan, atau untuk membantu dalam membuat keputusan bagi produk dan perkhidmatan kami atau pelanggan kami, atau di mana kami mempunyai kepentingan perniagaan yang sah, atau sebab sah yang lain untuk pengumpulan dan penggunaan sedemikian. Maklumat peribadi yang anda serahkan kepada kami juga boleh digunakan untuk mengesahkan identiti anda apabila anda mengakses satu Tinjauan, bantu mengurus hubungan kami dengan anda, termasuklah mana-mana permintaan daripada anda, atau untuk menyesuaikan atau menambah baik Tinjauan kami.',
    skipRewardPrivacyNoticeContent4: 'Kami juga boleh menghubungi anda untuk mendapatkan maklum balas mengenai perkhidmatan yang disediakan oleh kami atau ahli gabungan kami untuk tujuan penyelidikan. Maklumat peribadi anda juga boleh digunakan untuk melindungi hak atau harta kami dan pengguna kami.',
    skipRewardPrivacyNoticeContent17: 'Sila ambil perhatian bahawa kami perlu memproses Data Peribadi anda untuk tujuan ini, di mana tanpanya anda tidak akan dapat mengambil bahagian dalam tinjauan ini.',
    skipRewardPrivacyNoticeContent5: 'Kami mungkin akan memberikan maklumat peribadi anda kepada ahli gabungan kami berkaitan dengan prestasi Tinjauan berkenaan, termasuklah analisis data yang dikumpul dalam Tinjauan. Maklumat peribadi anda juga mungkin didedahkan kepada pihak ketiga yang lain, seperti Philip Morris (Malaysia) Sdn Bhd, pelanggan kami, untuk membantu mereka membuat keputusan berkenaan dengan produk dan perkhidmatan mereka.',
    skipRewardPrivacyNoticeContent6: 'Maklumat peribadi anda juga boleh didedahkan kepada penguat kuasa undang-undang, badan penyeliaan atau agensi kerajaan lain, atau kepada pihak ketiga yang lain, untuk mematuhi kewajipan atau permintaan dari segi undang-undang, pengawalseliaan atau keselamatan negara.',
    skipRewardPrivacyNoticeContent7: 'Kami akan memberikan notis dan mendapatkan persetujuan anda sebelum berkongsi maklumat peribadi anda dengan pihak ketiga yang tidak berkaitan dan tidak melaksanakan perkhidmatan untuk kami, kecuali jika perkongsian tersebut dibenarkan oleh Notis Privasi atau Tinjauan ini.',
    skipRewardPrivacyNoticeContent8: 'Semua pendedahan ini mungkin melibatkan pemindahan maklumat peribadi ke negara atau wilayah tanpa peraturan perlindungan data seperti di kawasan penginapan anda.',
    skipRewardPrivacyNoticeContent9: 'Sebarang maklumat peribadi yang telah dirujuk di bawah "Pengumpulan Maklumat" seperti di atas boleh didedahkan kepada pihak ketiga yang dikenal pasti dalam bahagian ini untuk tujuan seperti yang dinyatakan di sini.',
    skipRewardPrivacyNoticeContent10: 'Kami akan menyimpan maklumat peribadi bagi tempoh masa yang munasabah untuk jalankan tujuan yang digariskan dalam Notis Privasi ini. Walau apa pun tempoh tujuan, kami mungkin akan menyimpan maklumat anda jika diperlukan untuk mematuhi kewajipan undang-undang atau profesional kami, menguatkuasakan perjanjian kami atau menyelesaikan pertikaian.',
    skipRewardPrivacyNoticeContent11: 'Anda mungkin boleh membuat pilihan tentang cara maklumat peribadi anda dikumpul, digunakan dan dikongsi.',
    skipRewardPrivacyNoticeContent12: 'Anda mungkin mempunyai hak untuk meminta kami mengesahkan sama ada kami memproses maklumat peribadi anda atau kami membetulkan, memadam atau berhenti memproses maklumat peribadi anda. Anda juga mungkin mempunyai hak untuk meminta kami memberikan maklumat berikut berkenaan dengan maklumat peribadi anda yang disimpan:',
    skipRewardPrivacyNoticeContent12SubContent1: 'Kategori dan/atau maklumat peribadi khusus yang kami kumpulkan.',
    skipRewardPrivacyNoticeContent12SubContent2: 'Kategori sumber dari mana maklumat peribadi dikumpulkan.',
    skipRewardPrivacyNoticeContent12SubContent3: 'Tujuan perniagaan atau komersial untuk mengumpul maklumat peribadi.',
    skipRewardPrivacyNoticeContent12SubContent4: 'Kategori pihak ketiga yang diberikan maklumat peribadi dari kami.',
    skipRewardPrivacyNoticeContent13: 'Untuk melaksanakan mana-mana hak anda di bawah undang-undang terpakai yang diterangkan di atas mengenai maklumat peribadi anda, sila hubungi kami di nombor ini 1-800-88-0033. Apabila menghubungi kami, sila berikan nama penuh anda, nombor telefon bimbit dan jenis permintaan khusus yang anda buat.',
    skipRewardPrivacyNoticeContent14: 'Kami mempunyai piawaian teknologi dan keselamatan operasi komersial yang munasabah untuk melindungi semua maklumat peribadi yang diberikan oleh pengguna berkenaan dengan Tinjauan daripada akses, pendedahan, pengubahan atau pemusnahan tanpa kebenaran.',
    skipRewardPrivacyNoticeContent15: 'Kami boleh mengubah suai atau meminda Notis Privasi ini dari semasa ke semasa mengikut budi bicara kami. Apabila kami membuat perubahan pada Notis Privasi ini, kami akan meminda tarikh semakan di bahagian atas halaman ini dan Notis Privasi yang diubah suai atau dipinda itu akan berkuat kuasa ke atas anda dan maklumat anda berdasarkan tarikh semakan tersebut. Kami menggalakkan anda menyemak Notis Privasi ini dari semasa ke semasa untuk lihat sebarang perubahan.',
    skipRewardPrivacyNoticeContent16: 'Untuk menguatkuasakan mana-mana hak anda di bawah mana-mana kategori yang diterangkan di atas berkenaan dengan maklumat peribadi anda, atau jika anda mempunyai soalan atau kebimbangan mengenai privasi anda berkaitan dengan Tinjauan ini, sila hubungi kami di +6011-5691 8469. Apabila anda menghubungi kami, sila berikan nama penuh anda, nombor telefon bimbit dan permintaan khusus yang anda ingin buat.',

    uniqueCodeAndPhoneNumberMessage1: 'Hi, terima kasih kerana berminat untuk menyertai tinjauan ini.',
    uniqueCodeAndPhoneNumberMessage2: 'Sila masukkan kod tinjauan yang terdapat di dalam pek sleeve anda dan nombor telefon bimbit anda untuk kami menghantar kod pengesahan.',
    uniqueCodeAndPhoneNumberHowToFindUniqueCode: 'Cari kod unik anda di dalam lengan pek.',

    uniqueCodeAndPhoneNumberStep1: 'LANGKAH 1',
    uniqueCodeAndPhoneNumberStep1Message: 'Buang pelekat pada bahagian tepi pelapik pek',

    uniqueCodeAndPhoneNumberStep2: 'LANGKAH 2',
    uniqueCodeAndPhoneNumberStep2Message: 'Buang pek pelapik dan periksa dalam pelapik untuk Kod Unik tersebut',

    uniqueCodeAndPhoneNumberError1: 'Sila masukkan kod unik!',
    uniqueCodeAndPhoneNumberError2: 'Nombor telefon tidak sah!',

    uniqueCodeAndPhoneNumberErrorBlocked: `Terima kasih kerana menyertai tinjaun ini. Kami menghargai pendapat anda.

    Namun demikian, anda telah melebihi had maklum balas yang diperlukan. 
    
    Terima kasih atas sokongan anda.`,

    bondOtpMessage1: `Sila masukkan OTP yang telah dihantar ke +${OTPLOV.phoneNumber}`,

    bondBasicInfoFirstTimeBuyerInputLabel: 'Adakah ini kali pertama anda membeli Bond Street Cigarillo?',
    bondBasicInfoFirstTimeBuyerInputLabelSS: 'Adakah ini kali pertama anda membeli Marlboro Summer Splash?',
    bondBasicInfoFirstTimeBuyerInputLabelTS: 'Adakah ini kali pertama anda membeli Marlboro Tropical Splash?',
    bondBasicInfoFirstTimeBuyerInputLabelGeneric: 'Adakah ini kali pertama anda membeli',
    bondBasicInfoFirstTimeBuyerYes: 'Ya',
    bondBasicInfoFirstTimeBuyerNo: 'Tidak',

    selectLanguagePlaceholder: 'Pilih bahasa',

    invalidUniqueCodeError: 'Kod unik ini sudah diguna. Sila masukkan kod unik baharu jika anda ingin meneruskan.',

    languageSelectionAndDOBHeaderContainerAgeRange: 'Sila nyatakan umur',

    surveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan ${InactiveSurvey.mainTitle}.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan ${InactiveSurvey.subTitle} yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the ${InactiveSurvey.mainTitle} consumer feedback survey.

    This survey has ended, however do look out for future ${InactiveSurvey.subTitle} consumer feedback surveys. Your reviews are important to us as we continue to improve.
    
    `,

    surveyNextText: 'Seterusnya',
    surveyBackText: 'Kembali',
    surveyQuestionNoAnswerToast: 'Sila jawab soalan ini',

    languageSelectionAndDOBAgeError: 'Harap maaf, anda perlu berumur sekurang-kurangnya 19 tahun untuk menyertai tinjauan ini.',
    noDOBAgeSelectedError: 'Sila pilih umur anda sebelum meneruskan.',

    languageSelectionAndDOBAgreementText: 'Saya telah membaca dan bersetuju dengan {terms}, {disclaimer}, dan bersetuju dengan {privacyNotice}',

    OTPrequest: 'Minta OTP',

    goBackPromptMessage: 'Adakah anda pasti mahu kembali? Anda akan kehilangan progres anda.',
    goBackToOtpPromptMessage: 'Anda perlu memasukkan semula nombor telefon anda untuk meneruskan tinjauan ini.',

    otherBrandsLabel: 'Lain',
    surveyTitle: 'Soalan Tinjauan',

    inactivityModalTitle: 'Perhatian',
    inactivityModalQuestion: 'Adakah anda masih menggunakan halaman ini?',
    inactivityModalYesButton: 'Ya',

    surveyQuestionsDisclaimerTerms: '* Maklumat yang terkandung di sini adalah sulit. Ia hanya untuk tujuan kajian penyelidikan dan kegunaan dalaman sahaja. Ia tidak berkaitan dengan aktiviti jualan atau promosi dalam apa jua bentuk. Sebarang pencetakan semula secara seluruhnya atau mana-mana bahagiannya adalah dilarang sama sekali.',

    selectOneWithShortAnswerTitle: 'Produk tembakau jenama lain (sila nyatakan)',

    clickNextToProceed: 'Sila tekan Seterusnya untuk meneruskan',
    clickSubmitToProceed: 'Sila tekan Hantar untuk meneruskan',
};
