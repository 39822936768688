import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { ITrackStageTimeParams } from 'redux/slices/home/types';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchTrackStageTime(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeTrackStageTimeAttempt', handleTrackStageTime, api);
}

function* handleTrackStageTime(api: HomeGateway, data: PayloadAction<ITrackStageTimeParams>) {
    const { stage, startTime, endTime } = data.payload;

    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeTrackStageTimeFailure());

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.trackStageTime], { scanId, stage, startTime, endTime });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeTrackStageTimeFailure());
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeTrackStageTimeSuccess());
    }
}
