import config from 'config';

import AuthGateway from './Auth';
import HomeGateway from './Home';
import RewardsGateway from './Rewards';
import SurveyGateway from './Survey';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const home = new HomeGateway(baseUrl);
const rewards = new RewardsGateway(baseUrl);
const survey = new SurveyGateway(baseUrl);

export default {
    auth,
    home,
    rewards,
    survey,
};
