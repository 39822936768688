import { css } from 'styled-components';

const MultipleToggleAnswerStyles = {
    nonSelected: css`
        background-color: #b3cde0;
        min-height: 40px;
        height: auto;
        width: 100%;
    `,
    selected: css`
        background-color: #0096FF;
        min-height: 40px;
        height: auto;
        width: 100%;
    `,
};

export default MultipleToggleAnswerStyles;
