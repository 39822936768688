import { StylesDictionary } from 'lib/StylesDictionary';
import { css } from 'styled-components';
import { StylesConfig } from 'react-select';

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        background: 'white',
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    questionContainer: {
        width: '100%',
        marginBottom: '20px',
    },
    loadingOverlay: {
        position: 'absolute',
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export const ItemStyles = {
    question: css`
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    `,
};

export const CustomDropdownStyles: StylesConfig = {
    container: (provided) => ({
        ...provided,
        cursor: 'pointer',
        fontSize: '15px',
    }),
    control: () => ({
        borderRadius: '0px',
        border: '1px solid #a5aab5',
        backgroundColor: 'white',
        width: '100%',
        display: 'flex',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#a5aab5',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#888888',
        fontSize: '15px',
    }),
    menu: (provided) => ({
        ...provided,
        color: '#888888',
        fontSize: '15px',
        borderRadius: '0px',
        marginTop: '0px',
        width: '100%',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#888888',
    }),
};
