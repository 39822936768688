import { LanguageSelectionEnum, IQrTypeEnum, ISurveyScanStageEnum } from 'api/HomeBase';
import { ISurvey } from 'entities/surveys';
import auth from 'redux/slices/auth/Selectors';
import home from 'redux/slices/home/Selectors';
import rewards from 'redux/slices/rewards/Selectors';
import error from 'redux/slices/error/Selectors';
import survey from 'redux/slices/survey/Selectors';

import { RootState } from './store';
import { ILanguagePack, ICommonCopies } from './slices/home/types';

const authGetAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);

const homeGetTrackScanAttempting = (state: RootState): boolean => home.getTrackScanAttempting(state.home);
const homeGetTrackScanError = (state: RootState): string => home.getTrackScanError(state.home);
const homeGetScanId = (state: RootState): string => home.getScanId(state.home);
const homeGetQrId = (state: RootState): string => home.getQrId(state.home);
const homeGetQrType = (state: RootState): IQrTypeEnum | undefined => home.getQrType(state.home);

const homeGetSelectLanguageAttempting = (state: RootState): boolean => home.getSelectLanguageAttempting(state.home);
const homeGetSelectLanguageError = (state: RootState): string => home.getSelectLanguageError(state.home);
const homeGetSelectedLanguage = (state: RootState): LanguageSelectionEnum => home.getSelectedLanguage(state.home);

const homeGetSetDOBAttempting = (state: RootState): boolean => home.getSetDOBAttempting(state.home);
const homeGetSetDOBError = (state: RootState): string => home.getSetDOBError(state.home);
const homeGetDob = (state: RootState): string => home.getDob(state.home);

const homeGetSetPhoneNumberAttempting = (state: RootState): boolean => home.getSetPhoneNumberAttempting(state.home);
const homeGetSetPhoneNumberError = (state: RootState): string => home.getSetPhoneNumberError(state.home);
const homeGetPhoneNumber = (state: RootState): string => home.getPhoneNumber(state.home);

const homeGetSubmitOTPAttempting = (state: RootState): boolean => home.getSubmitOTPAttempting(state.home);
const homeGetSubmitOTPError = (state: RootState): string => home.getSubmitOTPError(state.home);

const homeGetResendOTPAttempting = (state: RootState): boolean => home.getResendOTPAttempting(state.home);
const homeGetResendOTPError = (state: RootState): string => home.getResendOTPError(state.home);

const homeGetSubmitBasicInfoAttempting = (state: RootState): boolean => home.getSubmitBasicInfoAttempting(state.home);
const homeGetSubmitBasicInfoError = (state: RootState): string => home.getSubmitBasicInfoError(state.home);
const homeGetIsTrialist = (state: RootState): boolean | undefined => home.getIsTrialist(state.home);

const homeGetScanInfoAttempting = (state: RootState): boolean => home.getScanInfoAttempting(state.home);
const homeGetScanInfoError = (state: RootState): string => home.getScanInfoError(state.home);
const homeGetStage = (state: RootState): ISurveyScanStageEnum => home.getStage(state.home);

const homeGetSelectLanguageAndDOBAttempting = (state: RootState): boolean => home.getSelectLanguageAndDOBAttempting(state.home);
const homeGetSelectLanguageAndDOBError = (state: RootState): string => home.getSelectLanguageAndDOBError(state.home);

const homeGetTrackDOBAttempting = (state: RootState): boolean => home.getTrackDOBAttempting(state.home);

const homeGetSubmitUniqueCodeAndPhoneNumberAttempting = (state: RootState): boolean => home.getSubmitUniqueCodeAndPhoneNumberAttempting(state.home);
const homeGetSubmitUniqueCodeAndPhoneNumberError = (state: RootState): string => home.getSubmitUniqueCodeAndPhoneNumberError(state.home);

const homeGetSubmitBasicInfoAndSurveyStatusAttempting = (state: RootState): boolean => home.getSubmitBasicInfoAndSurveyStatusAttempting(state.home);
const homeGetSubmitBasicInfoAndSurveyStatusError = (state: RootState): string => home.getSubmitBasicInfoAndSurveyStatusError(state.home);

const homeGetCommonCopiesAttempting = (state: RootState): boolean => home.getCommonCopiesAttempting(state.home);
const homeGetCommonCopiesError = (state: RootState): string => home.getCommonCopiesError(state.home);
const homeGetCommonCopies = (state: RootState): ICommonCopies | undefined => home.getCommonCopies(state.home);

const homeGetLanguagePackAttempting = (state: RootState): boolean => home.getLanguagePackAttempting(state.home);
const homeGetLanguagePackError = (state: RootState): string => home.getLanguagePackError(state.home);
const homeGetLanguagePack = (state: RootState): ILanguagePack => home.getLanguagePack(state.home);

const homeGetScanInfoCanSkipUniqueCode = (state: RootState): boolean => home.getScanInfoCanSkipUniqueCode(state.home);
const homeGetScanInfoCanSkipReward = (state: RootState): boolean => home.getScanInfoCanSkipReward(state.home);

const homeGetScanInfoShowIsFirstTimeBuyerOption = (state: RootState): boolean => home.getScanInfoShowIsFirstTimeBuyerOption(state.home);

const homeGetScanInfoPhoneNumberRepeated = (state: RootState): boolean => home.getScanInfoPhoneNumberRepeated(state.home);
const homeGetScanInfoSurveyFlavor = (state: RootState): string => home.getScanInfoSurveyFlavor(state.home);

const homeIsOTPScreenReady = (state: RootState): boolean => home.getIsOTPScreenReady(state.home);

// rewards
const rewardsGetRewardsAttempting = (state: RootState): boolean => rewards.getRewardsAttempting(state.rewards);
const rewardsGetRewardsError = (state: RootState): string => rewards.getRewardsError(state.rewards);

const rewardsGetRewardsCode = (state: RootState): string => rewards.getRewardsCode(state.rewards);
const rewardsGetRewardsExpiry = (state: RootState): string => rewards.getRewardsExpiry(state.rewards);
const rewardsGetRewardsValue = (state: RootState): number => rewards.getRewardsValue(state.rewards);

const rewardsGetRewardsAnsweredYes = (state: RootState): boolean | undefined => rewards.getRewardsAnsweredYes(state.rewards);
const rewardsGetRewardsUrl = (state: RootState): string => rewards.getRewardsUrl(state.rewards);
const rewardsGetNoRewardsLeft = (state: RootState): boolean => rewards.getRewardsNoRewardsLeft(state.rewards);

const errorGetError = (state: RootState): string => error.getError(state.error);

const surveyGetAllSurveysAttempting = (state: RootState): boolean => survey.getAllSurveyAttempting(state.survey);
const surveyGetAllSurveysError = (state: RootState): string => survey.getAllSurveyError(state.survey);
const surveyGetAllSurveys = (state: RootState): ISurvey | null => survey.getAllSurvey(state.survey);

const surveySubmitAnswerAttempting = (state: RootState): boolean => survey.submitAnswerAttempting(state.survey);
const surveySubmitAnswerError = (state: RootState): string => survey.submitAnswerError(state.survey);

const surveyGetSurveyProgress = (state: RootState): number => survey.getSurveyProgress(state.survey);
const surveyGetSurveyHasBasicInfo = (state: RootState): boolean => survey.getSurveyHasBasicInfo(state.survey);

const homeGetSurveyName = (state: RootState): string => home.getSurveyName(state.home);
const homeGetSurveyQuestionsLength = (state: RootState): number => home.getSurveyQuestionsLength(state.home);

export default {
    authGetAuthToken,

    homeGetTrackScanAttempting,
    homeGetTrackScanError,
    homeGetScanId,
    homeGetQrId,
    homeGetQrType,

    homeGetSelectLanguageAttempting,
    homeGetSelectLanguageError,
    homeGetSelectedLanguage,

    homeGetSetDOBAttempting,
    homeGetSetDOBError,
    homeGetDob,

    homeGetSetPhoneNumberAttempting,
    homeGetSetPhoneNumberError,
    homeGetPhoneNumber,

    homeGetSubmitOTPAttempting,
    homeGetSubmitOTPError,

    homeGetResendOTPAttempting,
    homeGetResendOTPError,

    homeGetSubmitBasicInfoAttempting,
    homeGetSubmitBasicInfoError,
    homeGetIsTrialist,

    homeGetScanInfoAttempting,
    homeGetScanInfoError,
    homeGetStage,

    homeGetSelectLanguageAndDOBAttempting,
    homeGetSelectLanguageAndDOBError,

    homeGetTrackDOBAttempting,

    homeGetSubmitUniqueCodeAndPhoneNumberAttempting,
    homeGetSubmitUniqueCodeAndPhoneNumberError,

    homeGetSubmitBasicInfoAndSurveyStatusAttempting,
    homeGetSubmitBasicInfoAndSurveyStatusError,

    homeGetCommonCopiesAttempting,
    homeGetCommonCopiesError,
    homeGetCommonCopies,

    homeGetLanguagePackAttempting,
    homeGetLanguagePackError,
    homeGetLanguagePack,

    homeGetScanInfoCanSkipUniqueCode,
    homeGetScanInfoCanSkipReward,

    homeGetScanInfoShowIsFirstTimeBuyerOption,

    homeGetScanInfoPhoneNumberRepeated,
    homeGetScanInfoSurveyFlavor,

    rewardsGetRewardsAttempting,
    rewardsGetRewardsError,

    rewardsGetRewardsCode,
    rewardsGetRewardsExpiry,
    rewardsGetRewardsValue,

    rewardsGetRewardsAnsweredYes,
    rewardsGetRewardsUrl,
    rewardsGetNoRewardsLeft,

    surveyGetAllSurveysAttempting,
    surveyGetAllSurveysError,
    surveyGetAllSurveys,

    surveySubmitAnswerAttempting,
    surveySubmitAnswerError,

    errorGetError,

    homeIsOTPScreenReady,

    surveyGetSurveyProgress,
    surveyGetSurveyHasBasicInfo,

    homeGetSurveyName,
    homeGetSurveyQuestionsLength,
};
