import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { IGetLanguagePackParams } from 'redux/slices/home/types';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchGetLanguagePack(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeGetLanguagePackAttempt', handleGetLanguagePack, api);
}

function* handleGetLanguagePack(api: HomeGateway, data: PayloadAction<IGetLanguagePackParams>) {
    const { qrType, lang } = data.payload;

    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeGetLanguagePackFailure(''));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.getLanguagePack], { scanId, qrType, lang });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeGetLanguagePackFailure(''));
        yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeGetLanguagePackSuccess({ qrType, ...response.data }));
    }
}
