import React, { FunctionComponent } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Utils from 'lib/Utils';

const PrivateRoute: FunctionComponent = () => {
    const scanId = Utils.LocalStorage.getItem('scanId');

    if (!scanId) return <Navigate replace to='/error' />;

    return (
        <div>
            <Outlet />
        </div>
    );
};

export default PrivateRoute;
