import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { GetRewardsParams, GetRewardsResponse, IRewardsGateway } from './RewardsBase';

const getHeaders = (scanId: string) => ({
    headers: {
        'X-Scan-Id': scanId,
    },
});

export default class RewardsGateway extends IRewardsGateway {
    async getRewards(params: GetRewardsParams): GatewayResponse<GetRewardsResponse> {
        const { scanId } = params;
        const response: ApiResponse<GetRewardsResponse> = await this.api.get('/c/rewards', {}, getHeaders(scanId));
        return this.process(response);
    }
}
