import React, { useState, FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IDataToSubmit, IOption } from 'entities/surveys';

import Text from 'components/Text';

import ToggleButton from 'components/ToggleButton';
import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/DropdownStyles';
import MultipleToggleAnswerStyles from './styles/MultipleToggleAnswerStyles';

interface MultipleToggleAnswerProps {
    surveyId: string;
    questionId: string;
    question: string;
    options: IOption[];
    images: string[];
    loading: boolean;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const MultipleToggleAnswer: FunctionComponent<MultipleToggleAnswerProps> = (props: MultipleToggleAnswerProps) => {
    const { surveyId, questionId, question, options, images, changeQuestionStatus, submitAnswer, loading } = props;

    const [answerSelected, setAnswerSelected] = useState('');

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    const onSelectedHandler = (id: string) => {
        setAnswerSelected(id);
        const dataToSubmit = {
            surveyId,
            questionId,
            selectedOptionId: id,
        };
        changeQuestionStatus(questionId, true);
        submitAnswer(dataToSubmit);
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            <div style={ContainerStyles.questionContainer}>

                <div
                    style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}
                >
                    {renderQuestion()}
                </div>
                {images.length > 0 && (
                    <Grid
                        isOnlyOne={(images.length === 1)}
                    >
                        {images.map(url => {
                            return (
                                <img
                                    key={url}
                                    src={url}
                                    alt='no'
                                    id='img'
                                    style={{ pointerEvents: 'none' }}
                                />
                            );
                        })}
                    </Grid>
                )}

                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px',
                            width: '100%',
                        }}
                    >
                        {options.map(option => (
                            <ToggleButton
                                isSelected={answerSelected === option.id}
                                style={answerSelected === option.id ? MultipleToggleAnswerStyles.selected : MultipleToggleAnswerStyles.nonSelected}
                                onClick={() => {
                                    onSelectedHandler(option.id);
                                }}
                            >
                                {option.value}
                            </ToggleButton>
                        ))}
                    </div>
                    {loading && (
                        <div style={ContainerStyles.loadingOverlay} />
                    )}
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

export default connect(null, mapDispatchToProps)(MultipleToggleAnswer);
