import React, { useEffect } from 'react';
import Routes from 'navigation/Routes';
import ReactGA from 'react-ga4';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';

import { Spinner } from 'reactstrap';

import config from 'config/index';
import packageInfo from '../../package.json';

interface FullProps {
    gettingScanInfo: boolean;
    gettingCommonCopies: boolean;
    gettingLanguagePack: boolean;
    scanId: string;
    getScanInfo: () => void;
}

const Full = (props: FullProps): JSX.Element => {
    const {
        gettingScanInfo,
        gettingCommonCopies,
        gettingLanguagePack,
        scanId,
        getScanInfo,
    } = props;
    const currentScreen = window.location.href;

    useEffect(() => {
        if (!currentScreen.match('/q/') || currentScreen.match('/b/q/') || currentScreen.match('/m/q/')) {
            getScanInfo();
        }

        ReactGA.gtag('config', { send_page_view: false });

        console.log(`Client - Research Anytime: v.${packageInfo.version} (${config.env})`);
    }, []);

    // Error is handled in saga, user will be redirected to general error screen
    if (gettingScanInfo || gettingLanguagePack || gettingCommonCopies) {
        return (
            <div style={{
                height: '100vh',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            >
                <div style={{ margin: 'auto' }}>
                    <Spinner type='grow' />
                    <Spinner type='grow' style={{ margin: '0px 5px' }} />
                    <Spinner type='grow' />
                </div>
            </div>
        );
    } return (
        <Routes />
    );
};

const mapStateToProps = (state: RootState) => ({
    gettingScanInfo: Selectors.homeGetScanInfoAttempting(state),
    gettingCommonCopies: Selectors.homeGetCommonCopiesAttempting(state),
    gettingLanguagePack: Selectors.homeGetLanguagePackAttempting(state),
    scanId: Selectors.homeGetScanId(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getScanInfo: () => dispatch(Actions.homeGetScanInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Full);
