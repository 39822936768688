import React, { useState, useEffect, FunctionComponent } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { ISurveyScanStageEnum } from 'api/HomeBase';
import Translate from 'translate/Translate';

interface TimeTrackerProps {
  stage: number;
}

const TimeTracker: FunctionComponent<TimeTrackerProps> = ({ stage }: TimeTrackerProps) => {
    const [timer, setTimer] = useState<dayjs.Dayjs | null>(null);
    const [showModal, setShowModal] = useState(false);

    // Start timer after page loads
    useEffect(() => {
        const time = dayjs();
        setTimer(time);
    }, []);

    // Handle timer by calculating the difference between the current time and the start time
    useEffect(() => {
        const interval = setInterval(() => {
            if (timer && stage !== (ISurveyScanStageEnum.BondStAnsweringSurvey || ISurveyScanStageEnum.BondStAnsweringSurvey)) {
                const currentTime = dayjs();
                const differenceInSeconds = currentTime.diff(timer, 'second');

                // Display timer when exceed 5 minutes of uninteractivity
                if (differenceInSeconds >= 300) {
                    setShowModal(true);
                    clearInterval(interval);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        // Resets timer when there is interaction
        const handleInteraction = () => {
            const time = dayjs();
            setTimer(time);
        };
        addEventListeners(handleInteraction);
        return () => {
            removeEventListeners(handleInteraction);
        };
    }, []);

    const addEventListeners = (handleInteraction: () => void) => {
        window.addEventListener('click', handleInteraction);
        window.addEventListener('keydown', handleInteraction);
        window.addEventListener('mousemove', handleInteraction);
        window.addEventListener('touchstart', handleInteraction);
    };

    const removeEventListeners = (handleInteraction: () => void) => {
        window.removeEventListener('click', handleInteraction);
        window.removeEventListener('keydown', handleInteraction);
        window.removeEventListener('mousemove', handleInteraction);
        window.removeEventListener('touchstart', handleInteraction);
    };

    return (
        showModal ? (
            <Modal centered isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(!showModal)}>{Translate.t('inactivityModalTitle')}</ModalHeader>
                <ModalBody>
                    {Translate.t('inactivityModalQuestion')}
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            background: '#199BE1',
                            color: 'black',
                        }}
                        onClick={() => setShowModal(false)}
                    >
                        {Translate.t('inactivityModalYesButton')}
                    </Button>
                </ModalFooter>
            </Modal>
        ) : null
    );
};

export default TimeTracker;
