import { push, replace, goBack, go } from 'redux-first-history';

import { store } from 'redux/store';

export interface INavActionState {
    [key: string]: any;
}

// NOTES:
// If you want to add new routes and is dependent on the stage survey,
// don't forget to modify getScanInfo saga and adjust accordingly

const navPush = (routeName: string, state?: INavActionState): void => {
    store.dispatch(push(routeName, state));
};

const navReplace = (routeName: string): void => {
    store.dispatch(replace(routeName));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navResetToLogin = (): void => navReplace('/login');

const navToHome = (): void => navPush('/');

const navToLanguageSelection = (qrId: string): void => navPush(`/m/q/${qrId}`);

const navToUnqualified = (): void => navPush('/unqualified');

const navToRewards = (): void => navPush('/completedSurvey');

const navToError = (): void => navPush('/error');

const navToSurvey = (): void => navPush('/v2/survey');

const navToLanguageSelectionAndDOB = (qrId: string): void => navPush(`/b/q/${qrId}`);

const navToUniqueCodeUsed = (): void => navPush('/uniqueCodeUsed');

const navToBasicInfoAndSurveyStatus = (): void => navPush('/b/basicInfoAndSurveyStatus');

export default {
    navBack,

    navResetToLogin,

    navToHome,

    navToLanguageSelection,

    navToUnqualified,

    navToRewards,

    navToError,

    navToSurvey,

    navToLanguageSelectionAndDOB,

    navToUniqueCodeUsed,

    navToBasicInfoAndSurveyStatus,

    navReplace,
};
