import { fork } from 'redux-saga/effects';

import RewardsGateway from 'api/Rewards';
import { RootSagaReturnType } from 'sagas/types';

import watchGetRewards from './getRewards';

export default (api: RewardsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetRewards, api);
    }

    return {
        rootSaga,
    };
};
