import React, { useEffect } from 'react';
import Translate from 'translate/Translate';
import ReactGA from 'react-ga4';

import Icons from 'assets/icons';
import Button from 'components/Button';
import Selectors from 'redux/Selectors';

import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';

import dayjs from 'dayjs';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { ContainerStyles, ItemStyles } from 'containers/home/styles';
import { ILanguagePack } from 'redux/slices/home/types';
import Text from 'components/Text';

interface RewardsProps {
    canSkipReward: boolean;
    gettingRewards: boolean;
    pinCode: string;
    pinExpiry: string;
    pinValue: number;
    scanId: string;
    answeredYes: boolean | undefined;
    noRewardsLeft: boolean;
    url: string;
    languagePack: ILanguagePack;
    getRewards: () => void;
    resetRewards: () => void;
}

const Rewards = (props: RewardsProps): JSX.Element => {
    const {
        canSkipReward,
        gettingRewards,
        pinCode,
        pinExpiry,
        pinValue,
        scanId,
        answeredYes,
        noRewardsLeft,
        url,
        languagePack,
        getRewards,
        resetRewards,
    } = props;

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (scanId) getRewards();

        return () => {
            resetRewards();
        };
    }, [scanId]);

    const renderRewardCopy = () => {
        if (answeredYes === undefined) {
            return false;
        } if (answeredYes === true) {
            return (
                <div style={{ marginTop: '10px', fontSize: '15px' }}>
                    {Translate.t('rewardsCopyAnsweredYesMessage1')}
                    {' '}
                    <a style={{ color: 'red' }} href={url} target='_blank' rel='noopener noreferrer'>{Translate.t('rewardsCopyAnsweredYesUrlLink')}</a>
                    {' '}
                    {Translate.t('rewardsCopyAnsweredYesMessage2')}
                </div>
            );
        } return (
            <div style={{ marginTop: '10px', fontSize: '15px' }}>
                {Translate.t('rewardsCopyAnsweredNoMessage1')}
                {' '}
                <a style={{ color: 'red' }} href={url} target='_blank' rel='noopener noreferrer'>{Translate.t('rewardsCopyAnsweredNoUrlLink')}</a>
                {' '}
                {Translate.t('rewardsCopyAnsweredNoMessage2')}
            </div>
        );
    };

    const renderRewardLayout = () => {
        if (noRewardsLeft) {
            return (
                <div style={{
                    margin: '40px auto 10px auto',
                    background: 'white',
                    padding: '20px',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
                >
                    {languagePack?.rewardsSubtitle}
                    <br />
                    <br />
                    {Translate.t('rewardsNoRewardsLeftMessage1')}
                </div>
            );
        }

        return (
            <div style={{
                margin: '40px auto 10px auto',
                background: 'white',
                padding: '20px',
                justifyContent: 'center',
                textAlign: 'center',
            }}
            >
                <div
                    style={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        whiteSpace: 'pre-line',
                    }}
                >
                    {languagePack?.rewardsSubtitle}
                    <br />
                    <br />
                    {languagePack?.rewardsMsg}
                </div>

                <div
                    style={{
                        color: '#A5AAB5',
                        marginTop: '20px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    {Translate.t('rewardsCardTnGPin')}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                        style={{
                            color: 'white',
                            background: '#021778',
                            padding: '10px',
                            fontSize: '30px',
                            justifyContent: 'center',
                            textAlign: 'center',
                            width: '80%',
                        }}
                    >
                        {gettingRewards ? <Spinner /> : (pinCode || 'XXXXXX')}
                    </div>
                    <Button
                        onClick={() => {
                            if (pinCode) {
                                toast.success('Code copied!');
                                navigator.clipboard.writeText(pinCode);
                            }
                        }}
                        style={{
                            margin: '0px -20px 0px 5px',
                            padding: '0px',
                            width: '15px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <img alt='copy icon' src={Icons.Copy} />
                    </Button>
                </div>

                <div
                    style={{
                        color: '#A5AAB5',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        textAlign: 'start',
                        width: '80%',
                        margin: '10px auto',
                        display: 'flex',
                    }}
                >
                    {Translate.t('rewardsCardVoucherExpiryDateText')}
                    <div
                        style={{
                            marginLeft: '5px',
                            color: '#021778',
                        }}
                    >
                        {pinExpiry.length ? dayjs(pinExpiry).format('DD/MM/YYYY') : ''}
                    </div>
                </div>
            </div>
        );
    };

    const renderSkipRewardLayout = () => {
        return (
            <div style={{ height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'center' }}>
                <div style={{ fontSize: '22px', textAlign: 'center', whiteSpace: 'pre-line', color: 'white' }}>
                    {languagePack?.rewardsSubtitle}
                </div>
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {canSkipReward ? (renderSkipRewardLayout()) : (
                <div style={{
                    textAlign: 'start',
                    flexWrap: 'wrap',
                    color: 'black',
                    width: 'auto',
                    marginTop: '40px',
                }}
                >
                    {renderRewardLayout()}
                    <div
                        style={{
                            fontSize: '15px',
                            color: 'white',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {noRewardsLeft ? '' : languagePack?.rewardsSendSmsMsg}
                    </div>
                    {renderRewardCopy()}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    gettingRewards: Selectors.rewardsGetRewardsAttempting(state),
    pinCode: Selectors.rewardsGetRewardsCode(state),
    pinExpiry: Selectors.rewardsGetRewardsExpiry(state),
    pinValue: Selectors.rewardsGetRewardsValue(state),
    scanId: Selectors.homeGetScanId(state),
    answeredYes: Selectors.rewardsGetRewardsAnsweredYes(state),
    noRewardsLeft: Selectors.rewardsGetNoRewardsLeft(state),
    url: Selectors.rewardsGetRewardsUrl(state),
    languagePack: Selectors.homeGetLanguagePack(state),
    canSkipReward: Selectors.homeGetScanInfoCanSkipReward(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getRewards: () => dispatch(Actions.rewardsGetRewardsAttempt()),
    resetRewards: () => dispatch(Actions.rewardsResetRewards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
