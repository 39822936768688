import { fork } from 'redux-saga/effects';

import api from 'api';

import home from './home';
import rewards from './rewards';
import survey from './survey';

import { SagaForkReturnType } from './types';

const apiInstance = api;

export default function* root(): SagaForkReturnType {
    yield fork(home(apiInstance.home, apiInstance.survey).rootSaga);
    yield fork(rewards(apiInstance.rewards).rootSaga);
    yield fork(survey(apiInstance.survey).rootSaga);
}
