import { createSlice } from '@reduxjs/toolkit';
import { AuthReduxState } from './types';

const initialState: AuthReduxState = {
    authToken: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
});

export type AuthState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
