import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { ITrackDisclaimerCheckboxEngagementParams } from 'redux/slices/home/types';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchTrackDisclaimerCheckboxEngagement(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeTrackDisclaimerCheckboxEngagementAttempt', handleTrackDisclaimerCheckboxEngagement, api);
}

function* handleTrackDisclaimerCheckboxEngagement(api: HomeGateway, data: PayloadAction<ITrackDisclaimerCheckboxEngagementParams>) {
    const { tncAndPrivacyPolicyCheckbox, notFromTheseIndustriesCheckbox } = data.payload;

    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeTrackDisclaimerCheckboxEngagmentFailure());

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.trackDisclaimerCheckboxEngagement], { scanId, tncAndPrivacyPolicyCheckbox, notFromTheseIndustriesCheckbox });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeTrackDisclaimerCheckboxEngagmentFailure());
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeTrackDisclaimerCheckboxEngagementSuccess());
    }
}
