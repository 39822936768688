import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import SurveyGateway from 'api/Survey';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';

export default function* watchGetAllSurveys(api: SurveyGateway): SagaWatcherReturnType {
    yield takeEvery('survey/getAllSurveysAttempt', handleGetAllSurveys, api);
}

function* handleGetAllSurveys(api: SurveyGateway) {
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.getAllSurveysFailure(''));
        yield put(Actions.errorSetGeneralErrorPageMessage('Something went wrong. Please try again'));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.getAllSurveys], { scanId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getAllSurveysFailure(response.message));

        if (response.name) {
            yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
        } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response?.data) {
            const { questions } = response.data;
            const parsedResponseData = questions?.map(item => {
                return {
                    ...item,
                    answered: false,
                    loading: false,
                };
            });

            response.data.questions = parsedResponseData;
        }
        yield put(Actions.getAllSurveysSuccess(response.data));
    }
}
