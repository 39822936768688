import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import NavActions from 'lib/NavActions';

import Actions from 'redux/Actions';
import { ISelectLanguageAndDOBParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';
import { LanguageSelectionEnum } from 'api/HomeBase';
import dayjs from 'dayjs';

export default function* watchSelectLanguageAndDOB(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSelectLanguageAndDOBAttempt', handleSelectLanguageAndDOB, api);
}

function* handleSelectLanguageAndDOB(api: HomeGateway, data: PayloadAction<ISelectLanguageAndDOBParams>) {
    const { lang, dob, stage, startTime } = data.payload;
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!lang) {
        yield put(Actions.homeSelectLanguageAndDOBFailure('Please select a language'));
        return;
    }

    if (!dob) {
        yield put(Actions.homeSelectLanguageAndDOBFailure('Please enter your date of birth'));
    }

    const response = yield* call([api, api.selectLanguageAndDOB], { scanId, lang, dob });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeSelectLanguageAndDOBFailure(''));

        if (response.name) {
            yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
        } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeSelectLanguageAndDOBSuccess({ lang, dob, stage, startTime }));
        Utils.User.setUserLanguage(LanguageSelectionEnum[lang]);

        // Submit Time Tracker
        const endTime = dayjs().toISOString();
        const params = { stage, startTime, endTime };
        yield put(Actions.homeTrackStageTimeAttempt(params));

        // Clear stored answers local storage
        Utils.LocalStorage.clearAnswer();
        NavActions.navToSurvey();
    }
}
