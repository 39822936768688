import { ISurvey } from 'entities/surveys';
import { SurveyState } from '.';

const getAllSurveyAttempting = (state: SurveyState): boolean => state.actions.getAllSurvey || false;
const getAllSurveyError = (state: SurveyState): string => state.error.getAllSurvey || '';
const getAllSurvey = (state: SurveyState): ISurvey | null => state.surveys || null;

const submitAnswerAttempting = (state: SurveyState): boolean => state.actions.submitAnswer || false;
const submitAnswerError = (state: SurveyState): string => state.error.submitAnswer || '';

const getSurveyProgress = (state: SurveyState): number => state.surveyProgress || 0;
const getSurveyHasBasicInfo = (state: SurveyState): boolean => state.surveyHasBasicInfo || false;

export default {
    getAllSurveyAttempting,
    getAllSurveyError,
    getAllSurvey,

    submitAnswerAttempting,
    submitAnswerError,

    getSurveyProgress,
    getSurveyHasBasicInfo,
};
