import { fork } from 'redux-saga/effects';

import SurveyGateway from 'api/Survey';
import { RootSagaReturnType } from 'sagas/types';

import watchGetAllSurveys from './getAllSurveys';
import watchSubmitAnswer from './submitAnswer';

export default (api: SurveyGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetAllSurveys, api);
        yield fork(watchSubmitAnswer, api);
    }

    return {
        rootSaga,
    };
};
