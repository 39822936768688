import { StylesDictionary } from 'lib/StylesDictionary';
import { css } from 'styled-components';

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        height: 'auto',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
    },
    topContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
    },
    bottomContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: '40px',
    },
    welcomeContainer: {
        fontWeight: 'bold',
        color: 'black',
        marginTop: '40px',
        fontSize: '15px',
        whiteSpace: 'pre-line',
    },
    pickContainer: {
        marginTop: '40px',
    },
    buttonsContainer: {
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
    },
    disclaimerContainer: {
        backgroundColor: 'white',
        marginTop: '40px',
        fontSize: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        boxSizing: 'border-box',
    },
    errorContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        height: '100dvh',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
};

export const ItemStyles = {
    pickYourLanguage: css`
        font-size: 28px;
        color: white;
        font-weight: bold;
        white-space: pre-line;
    `,
    languageButton: css`
        background-color: white;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 0px;

        margin-top: 5px;
        margin-bottom: 5px;

        height: 60px;

        padding-left: 40px;
        padding-right: 40px;

        font-size: 16px;
    `,
    languageButtonSelected: css`
        background-color: #199be1;
        width: 100%;

        color: white;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 0px;

        margin-top: 5px;
        margin-bottom: 5px;

        height: 60px;

        padding-left: 40px;
        padding-right: 40px;

        font-size: 16px;

        #check {
            width: 25px;
            height: 25px;
        }
    `,
    submitButton: css`
        background-color: #199be1;
        width: 60%;
        border-radius: 0px;
        color: white;
        height: 50px;
        font-size: 16px;
        font-weight: bold;
    `,
    errorText: css`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 40px;
        color: black;
        height: fit-content;
        text-align: center;
        font-size: 20px;
        //font-weight: bold;
        filter: drop-shadow(0px 0px 10px #1313135e);
`,
    ErrorMessage: {
        marginTop: '5px',
        color: 'red',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
    },
    DisclaimerButtons: {
        background: 'transparent',
        color: 'black',
        width: 'auto',
        textDecorationLine: 'underline',
        fontSize: '16px',
        margin: '0px',
        padding: 0,
        height: 'auto',
    },
    CancelButton: {
        background: 'transparent',
        color: 'black',
        marginRight: '40px',
        borderRadius: '0px',
        textDecorationLine: 'underline',
        textUnderlineOffset: '7px',
        letterSpacing: '1px',
        fontWeight: 'bold',
        height: '50px',
    },
    ProceedButton: {
        background: '#199BE1',
        color: 'white',
        borderRadius: '0px',
        letterSpacing: '1px',
        fontWeight: 'bold',
        height: '50px',
        width: '100%',
    },
    DisabledInput: {
        minHeight: '46px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        border: 'none',
        backgroundColor: '#ffffffa7',
        paddingLeft: '15px',
        fontSize: '15px',
        borderBottom: '1px solid rgb(246,246,246, 0.5)',
        color: 'grey',
    },
};
