import OTPLOV from 'lib/LOVs/OTP';
import InactiveSurvey from 'lib/LOVs/InactiveSurvey';

export default {
    trackScanSurveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan Marlboro Vista.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan Marlboro yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the Marlboro Vista consumer feedback survey.

    This survey has ended, however do look out for future Marlboro consumer feedback surveys. Your reviews are important to us as we continue to improve.

    `,

    dobMessage1: '继续使用本网站，即表示你确认已经阅读并接受我们的',
    dobMessageTermsAndConditions: '条件与规则',
    dobMessageAnd: '和',
    dobMessagePrivacyNotice: '隐私声明',
    dobMessageFirstParagraphFullStop: '。',
    dobMessage2: '请在下面输入你的出生日期，以确认你已年满19岁。继续参与意味着你已经确认自己是吸烟者，并了解参与本次调查为自愿性质。',
    dobDatepickerPlaceholder: '年份',
    dobMessage3: '参与者将获得5令吉(RM5)回扣作为完成本次调查的感谢奖励。​',
    dobCancelButton: '取消',
    dobProceedButton: '继续',
    dobTermsAndConditionsModalHeader: 'Terms & Conditions',
    dobTermsAndConditionsModalOkButton: '继续',
    dobPrivacyNoticeModalHeader: 'Privacy Notice',
    dobPrivacyNoticeModalOkButton: '继续',

    uniqueCodeHeader: '独特代码',
    uniqueCodeMessage1: 'Please enter the unique code:',
    uniqueCodeInputLabel: '独特编码',
    uniqueCodeNextButton: 'NEXT',

    uniqueCodeErrorMessage1: '请输入唯一代码',
    uniqueCodeErrorMessage2: '无效的电话号码',

    uniqueCodeUsedMessage1: '感谢你有兴趣参与这项调查。 ',
    uniqueCodeUsedMessage2: '非常抱歉！这个独特编码已经使用过。',
    uniqueCodeUsedMessage3: '如果你想要参与这项调查，请输入新的独特编码。我们感谢你抽出时间参与。',

    phoneNumberMessage1: '你好，我们非常高兴看到你对我们的调查感兴趣。',
    phoneNumberMessage2: '请输入您的手机号码和调查独特代码，以便我们向您发送OTP。',
    phoneNumberMessage3: '请输入你的手机号码，以便我们发送验证码。',

    phoneNumberMobileNoInputLabel: '手机号码',
    phoneNumberSubmitButton: '提交',

    errorPhoneUsedMessage1: '非常抱歉！你之前已回答过此调查。',
    errorPhoneUsedMessage2: '​我们感谢你抽出时间参与。',

    otpHeader: 'OTP 验证码已被发送。',
    otpMessage1: '请输入发送到你手机的验证码，以验证你的号码。',
    otpCodeInputLabel: 'OTP',
    otpResendOtpButton: '重发 OTP',
    otpResendOtpButtonSeconds: '秒',
    otpNextButton: '提交',

    otpResendSuccess: 'OTP重新发送成功！',
    optResendFailure: ' 重新发送失败。请再试一次',
    otpResendFailure2: '请等待至少 30 秒，然后再重新发送 OTP',

    otpSubmitError: 'OTP无效。请索取一份新的。',

    basicInfoHeader: '让我们来了解你',
    basicInfoGenderInputLabel: '性',
    basicInfoGenderMale: '男',
    basicInfoGenderFemale: '女',
    basicInfoMobileNumberInputLabel: '手机号码',
    basicInfoDOBInputLabel: '出生日期',
    basicInfoResidingStateInputLabel: '居住地区',
    basicInfoResidingStateJohor: '柔佛',
    basicInfoResidingStateKedah: '吉打',
    basicInfoResidingStateKelantan: '吉兰丹',
    basicInfoResidingStateMalacca: '马六甲',
    basicInfoResidingStateNegeriSembilan: '森美兰',
    basicInfoResidingStatePahang: '彭亨',
    basicInfoResidingStatePerak: '霹雳',
    basicInfoResidingStatePerlis: '玻璃市',
    basicInfoResidingStatePenang: '槟城',
    basicInfoResidingStateSabah: '沙巴',
    basicInfoResidingStateSarawak: '砂拉越',
    basicInfoResidingStateSelangor: '雪兰莪',
    basicInfoResidingStateTerengganu: '丁加奴',
    basicInfoResidingStateKualaLumpur: '吉隆坡联邦直辖区',
    basicInfoResidingStateLabuan: '纳闽联邦直辖区',
    basicInfoResidingStatePutrajaya: '布城联邦直辖区',
    basicInfoEthnicityInputLabel: '种族',
    basicInfoEthnicityMalay: '马来人',
    basicInfoEthnicityChinese: '华人',
    basicInfoEthnicityIndian: '印度人',
    basicInfoEthnicityOthers: '其他',
    basicInfoProfessionInputLabel: '你是否在以下任何一个领域工作？',
    basicInfoProfessionAdvertisingJournalismPR: '广告，新闻，公共关系',
    basicInfoProfessionGovernmentOfficerHealthcareTobacco: '政府公务员，医疗保健，烟草业,',
    basicInfoProfessionMarketResearch: '市场调查',
    basicInfoProfessionOtherProfessions: '我不从事以下任何职业',
    basicInfoProfessionBlockedProfessions: '是',
    basicInfoProfessionNotBlockedProfessions: '否',
    basicInfoProfessionBankingOrFinance: '银行/金融',
    basicInfoProfessionManufacturing: '制造业',
    basicInfoProfessionTelecommunications: '电信',
    basicInfoProfessionDistributionOrLogistic: '分销/物流',
    basicInfoProfessionECommerceOrInformationTechnology: '电子商务/资讯科技',
    basicInfoProfessionMediaAndCommunicationsOrGovernmentOfficerOrHealthcare: '媒体与通讯/政府公务员/医疗保健',
    basicInfoProfessionMarketResearchOrPublicRelationsOrTobacco: '市场调查/公关/烟草业',
    basicInfoProfessionSalesOrMarketing: '销售/营销',
    basicInfoProfessionOthers: '以上皆不是',
    basicInfoProfessionHospitalityOrFoodAndBeverage: '接待/餐饮',
    basicInfoNicotineBrandInputLabel: '您最常抽的香烟牌子是什么？',
    basicInfoNicotineFlavorInputLabel: '你定期选择的烟草口味？​',
    basicInfoNicotineFlavorInputLabel2: ' 你定期选择的烟草口味？',
    basicInfoSticksSmokedInputLabel: '你一天平均吸多少支品牌生产的香烟?',
    basicInfoSticksSmokedLessThanOnePerWeek: '每星期少于1支',
    basicInfoSticksSmokedMoreThanOnePerWeek: '每星期超过1支',
    basicInfoCurrentlyBreastfeedingInputLabel: '你目前正在怀孕或哺乳吗？​',
    basicInfoCurrentlyBreastfeedingYes: '是',
    basicInfoCurrentlyBreastfeedingNo: '不是',
    basicInfoProceedButton: '提交',
    basicInfoErrorMessage: '请回答所有问题',

    unqualifiedMessage1: '非常抱歉！我们很遗憾通知你，你不符合这项调查的基准条件',
    unqualifiedMessage2: '​我们感谢你抽出时间参与。',

    answerMultipleStatusHeader: 'Thank you! Let\'s get started with the survey.',
    answerMultipleMessage1: 'Is this your first time buying Bond Street Cigarillo?',
    answerMultipleYesButton: 'YES',
    answerMultipleNoButton: 'NO',

    surveySelectDropdown: '选择 ...',
    surveyFillAllSurveyErrorMessage: '请回答所有问题',
    surveyShortFormAnswerErrorMessage: '请填写空白字段',
    surveySubmitButton: '下一步',

    rewardsCardTnGPin: 'TnG识别码',
    rewardsCardVoucherValueText: '优惠券值:',
    rewardsCardVoucherExpiryDateText: '优惠券有效期限:',
    rewardsCopyAnsweredNoMessage1: '点击',
    rewardsCopyAnsweredNoUrlLink: '此处',
    rewardsCopyAnsweredNoMessage2: '了解更好的吸烟替代方案',
    rewardsCopyAnsweredYesMessage1: '点击',
    rewardsCopyAnsweredYesUrlLink: '此处',
    rewardsCopyAnsweredYesMessage2: '了解更多关于IQOS和lil产品资讯。',

    rewardsNoRewardsLeftMessage1: '由于反应热烈，我们分配的 RM2 礼券已全部兑换完毕。',

    generalError: '抱歉！出了点差错。请重试。',
    rewardAlreadyAwardedError: '已给予奖励。',

    languageSelectionAndDOBHeaderContainerTitle: '年龄验证',
    languageSelectionAndDOBHeaderContainerSubTitle: '请在下方输入你的出生日期，以确认你已年满19岁。',
    languageSelectionAndDOBheaderContainerSubTitle2: '你的参与是自愿性的，所有回复均保密，且仅用于调查研究目的。',

    languageSelectionAndDOBHeaderContainerDatePick: '选择你的出生日期',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderMonth: '月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth1: '一月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth2: '二月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth3: '三月 ',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth4: '四月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth5: '五月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth6: '六月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth7: '七月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth8: '八月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth9: '九月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth10: '十月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth11: '十一月',
    languageSelectionAndDOBEnterBirthdayDatepickerMonth12: '十二月',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholderYear: '年',
    languageSelectionAndDOBHeaderContainerMessage1: '我在此声明并确认：',
    languageSelectionAndDOBHeaderContainerMessage2: '我在此进一步声明并确认，我目前不在以下行业工作：',
    languageSelectionAndDOBHeaderContainerContent1: '我已年满 19 岁',
    languageSelectionAndDOBHeaderContainerContent2: ' 我目前没有怀孕或哺乳。',
    languageSelectionAndDOBHeaderContainerContent3: '我已阅读并同意',
    languageSelectionAndDOBHeaderContainerContent4: '我已阅读并同意',
    languageSelectionAndDOBHeaderContainerContent5: '过去 2 个月我每周吸超过 1 支烟',
    languageSelectionAndDOBHeaderContainerContent6: '在过去 2  个月内，我每周吸烟超过 1  支。',
    languageSelectionAndDOBHeaderContainerContent7: '广告、新闻、公共关系、市场研究。',
    languageSelectionAndDOBHeaderContainerContent8: '政府官员、法律、医疗保健、烟草',
    languageSelectionAndDOBHeaderContainerContent9: '市场调查',
    languageSelectionAndDOBHeaderContainerTermsAndConditions: '使用条款',
    languageSelectionAndDOBHeaderContainerPrivacyNotice: '隐私声明',
    languageSelectionAndDOBHeaderContainerAnd: ' 和',
    languageSelectionAndDOBHeaderContainerDisclaimer: '免责声明',
    languageSelectionAndDOBHeaderContainerFullStop: '。',
    languageSelectionAndDOBEnterBirthdayDatepickerPlaceholder: '年份',
    languageSelectionAndDOBSubmitButton: '提交',
    languageSelectionAndDOBTermsAndConditionsModalHeader: 'Terms Of Use [使用条款] ',
    languageSelectionAndDOBTermsAndConditionsModalOkButton: '提交',
    languageSelectionAndDOBPrivacyNoticeModalHeader: 'Privacy Notice [隐私声明]',
    languageSelectionAndDOBPrivacyNoticeModalOkButton: '提交',
    languageSelectionAndDOBDisclaimerModalHeader: 'Disclaimer [免责声明]',
    languageSelectionAndDOBDisclaimerModalOkButton: '提交',

    skipRewardTermsAndConditionTitle: '资格',
    skipRewardTermsAndConditionContent1: '本次调查仅对符合以下条件的法定年龄吸烟者开放：',
    skipRewardTermsAndConditionContent2: '年满 19 岁。',
    skipRewardTermsAndConditionContent3: '目前未怀孕或哺乳。',
    skipRewardTermsAndConditionContent4: '过去 2 个月内，每周吸烟量超过 1 支。',
    skipRewardTermsAndConditionContent5: '本次调查将按照先到先得原则进行以收集数据。',

    skipRewardDisclaimerContent1: '本调查不以任何方式促进吸烟和/或使用香烟产品/品牌或其他烟草产品。请不要在任何社交媒体或消息平台上讨论此调查。',
    skipRewardDisclaimerContent2: '本次调查仅供内部使用，旨在收集反馈意见，用于客户满意度评估、市场研究、品牌认知分析、产品改进和其他内部研究计划等目的。如隐私声明中所述，我们收集到的反馈意见将仅用于上述目的与 Philip Morris (Malaysia) Sdn Bhd 共享和披露。你的参与是自愿性的，所有回复都将保密，并仅限于上述目的使用。',
    skipRewardDisclaimerContent3: '吸烟是危险的，而且容易上瘾。目前没有安全的香烟，也不能假设任何香烟品牌变体或产品形式比其他香烟更安全。本次调查的目的不是解决吸烟和健康相关问题，也并非为了解决与开始吸烟、戒烟或尝试戒烟有关的问题。',

    skipRewardPrivacyNoticeTitle1: '简介',
    skipRewardPrivacyNoticeTitle2: '信息收集',
    skipRewardPrivacyNoticeTitle3: '信息使用',
    skipRewardPrivacyNoticeTitle4: '向第三方披露信息',
    skipRewardPrivacyNoticeTitle5: '信息保留',
    skipRewardPrivacyNoticeTitle6: '你的个人信息权利',
    skipRewardPrivacyNoticeTitle7: '信息安全',
    skipRewardPrivacyNoticeTitle8: '隐私声明的变更',
    skipRewardPrivacyNoticeTitle9: '联系信息',

    skipRewardPrivacyNoticeContent1: '本隐私声明描述了我们 Bike Bear Sdn Bhd 如何收集、使用和共享，与你完成的任何调查、焦点小组、访谈、研究或其他旨在收集由 Bike Bear Sdn Bhd 推行之活动(统称“调查”)的相关信息，这些调查链接或引用本隐私声明。通过回复其中一项调查，你同意根据本隐私声明使用此类信息。',
    skipRewardPrivacyNoticeContent2: '我们可能会收集你在完成调查时提供的个人信息，例如：',
    skipRewardPrivacyNoticeContent2subContent1: '居住州属',
    skipRewardPrivacyNoticeContent2subContent2: '种族',
    skipRewardPrivacyNoticeContent2subContent3: '电话号码',
    skipRewardPrivacyNoticeContent2subContent4: '出生日期',
    skipRewardPrivacyNoticeContent2subContent5: '与调查相关的任何其他信息',
    skipRewardPrivacyNoticeContent3: '我们将使用和调查与你相关的个人信息来实现调查中所阐述的目的，或协助我们或我们客户的产品和服务决策，或者在我们有合法商业利益或其他法律依据的情况下进行此类收集和使用。你提交给我们的个人信息也可能用于在你访问调查时验证你的身份、管理我们与你的关系(包括你的任何请求)，或定制或改进我们的调查。',
    skipRewardPrivacyNoticeContent4: '我们也可能出于研究目的联系你，征求你对我们或我们的关联公司提供的服务进行反馈。你的个人信息也可能用于保护我们和我们用户的权利或财产。',
    skipRewardPrivacyNoticeContent17: '请注意，我们有必要为这些目的处理你的个人数据，否则你将无法参与本次调查。',
    skipRewardPrivacyNoticeContent5: '我们可能会在调查执行过程中向我们的关联公司提供你的个人信息，包括对调查中收集的数据进行分析。你的个人信息也可能会披露给其他第三方，包括我们的客户 Philip Morris (Malaysia) Sdn Bhd，以协助他们做出有关其产品和服务的决策。',
    skipRewardPrivacyNoticeContent6: '个人信息也可能披露给执法、监管或其他政府机构，或其他第三方，在每种情况下都是为了遵守法律、监管或国家安全义务或要求。',
    skipRewardPrivacyNoticeContent7: '在与未为我们提供服务的非关联第三方共享你的个人信息之前，我们将给予通知并征得你的同意，除非本隐私声明或调查允许此类共享。',
    skipRewardPrivacyNoticeContent8: '所有这些披露可能涉及将个人信息转移到没有数据保护规则的国家或地区，例如你所在地区实施的数据保护规则。',
    skipRewardPrivacyNoticeContent9: '我们在上文“信息收集”中提到的任何个人信息，可能会出于本文所阐述的目的，向本章节中列明的第三方披露。',
    skipRewardPrivacyNoticeContent10: '我们会在本隐私声明所述目的的期限内合理保留个人信息。无论目的期限如何，我们可能会在必要时保留你的信息以履行我们的法律或专业义务、执行我们的协议或解决争议。',
    skipRewardPrivacyNoticeContent11: '你可以选择如何收集、使用和共享你的个人信息。',
    skipRewardPrivacyNoticeContent12: '你有权要求我们确认我们是否处理你的个人信息，或者我们是否更正、删除或停止处理你的个人信息。你也有权要求我们提供以下关于我们持有你个人信息的相关信息：',
    skipRewardPrivacyNoticeContent12SubContent1: '我们收集的个人信息的类别和/ 或具体内容。',
    skipRewardPrivacyNoticeContent12SubContent2: '收集个人信息的来源类别。',
    skipRewardPrivacyNoticeContent12SubContent3: '收集个人信息的业务或商业目的。',
    skipRewardPrivacyNoticeContent12SubContent4: '我们与之共享个人信息的第三方类别。',
    skipRewardPrivacyNoticeContent13: '若你想要根据上述有关你个人信息的适用法律行使你的任何权利，请致电 1-800-88-0033 联系我们。联系我们时请提供你的完整姓名、手机号码，以及你提出的具体要求类别。',
    skipRewardPrivacyNoticeContent14: '我们制定了合理的技术和运营安全商业标准，以保护用户就调查提供的所有个人信息免遭未经授权的访问、披露、更改或破坏。',
    skipRewardPrivacyNoticeContent15: '我们可能会不时自行修改或修订本隐私声明。当我们对本隐私声明进行更改时，我们将修改本页顶部的修订日期，并且此类修改或修订的隐私声明自修订日期起，对你和你的信息生效。我们建议你定期查看本隐私声明以查看任何更新。',
    skipRewardPrivacyNoticeContent16: '要行使上述有关你个人信息的任何权利，或者如果你对与调查相关的隐私有任何疑问或担忧，请拨打 +6011-5691 8469 与我们联系。致电我们时，请提供你的全名、手机号码以及你提出的具体请求类型。',

    uniqueCodeAndPhoneNumberMessage1: '你好！我们很高兴看到你有兴趣参与我们的调查。请填写以下信息，我们将向你发送验证码。',
    uniqueCodeAndPhoneNumberMessage2: '请输入您的手机号码和调查独特代码，以便我们向您发送OTP。',
    uniqueCodeAndPhoneNumberHowToFindUniqueCode: '在袖子内寻找您的独特代码。',

    uniqueCodeAndPhoneNumberStep1: '步骤1 ',
    uniqueCodeAndPhoneNumberStep1Message: '撕开包装旁侧的粘贴标签。',

    uniqueCodeAndPhoneNumberStep2: '步骤2',
    uniqueCodeAndPhoneNumberStep2Message: '查找二维码贴纸后方的唯一代码。',

    uniqueCodeAndPhoneNumberError1: '请输入唯一代码！',
    uniqueCodeAndPhoneNumberError2: '无效的电话号码！',

    uniqueCodeAndPhoneNumberErrorBlocked: `感谢您参与这项调查。 我们非常重视您的意见。

    不幸的是，您已超出所需的回应限制。
    
    感谢您的时间和努力。
    `,

    bondOtpMessage1: `请输入发给 +${OTPLOV.phoneNumber} 的OTP`,

    bondBasicInfoFirstTimeBuyerInputLabel: '这是你第一次购买Bond Street Cigarillo雪茄烟吗？',
    bondBasicInfoFirstTimeBuyerInputLabelSS: '这是你第一次购买Marlboro Summer Splash雪茄烟吗？',
    bondBasicInfoFirstTimeBuyerInputLabelTS: '这是你第一次购买Marlboro Tropical Splash雪茄烟吗？',
    bondBasicInfoFirstTimeBuyerInputLabelGeneric: '请问这是您第一次购买',

    bondBasicInfoFirstTimeBuyerYes: '是',
    bondBasicInfoFirstTimeBuyerNo: '否',

    selectLanguagePlaceholder: '选择语言',

    invalidUniqueCodeError: '此独特代码已经被使用过。如果您想继续，请输入新的独特代码。',

    languageSelectionAndDOBHeaderContainerAgeRange: '请说明你的年龄 :',

    surveyInactiveErrorMessage: `Terima kasih atas minat anda terhadap kajian maklum balas pelanggan ${InactiveSurvey.mainTitle}.

    Survei ini telah tamat. Nantikan survei maklum balas pelanggan ${InactiveSurvey.subTitle} yang seterusnya. Maklum balas anda amat kami hargai bagi tujuan penambahbaikan pada masa akan datang.

    
    Thank you for your interest in the ${InactiveSurvey.mainTitle} consumer feedback survey.

    This survey has ended, however do look out for future ${InactiveSurvey.subTitle} consumer feedback surveys. Your reviews are important to us as we continue to improve.
    
    `,

    surveyNextText: '下一题',
    surveyBackText: '后退',
    surveyQuestionNoAnswerToast: '请回答这个问题',

    languageSelectionAndDOBAgeError: '抱歉，您必须年满19岁才能参与此项调查',
    noDOBAgeSelectedError: '请选择您的年龄以继续。',

    languageSelectionAndDOBAgreementText: '我已阅读并同意{terms}、{disclaimer}，并同意{privacyNotice}。',

    OTPrequest: '要求OTP',

    goBackPromptMessage: '您确定要返回？你将会失去您的进度。',
    goBackToOtpPromptMessage: '您稍后必须再次输入您的电话号码才能继续。',

    otherBrandsLabel: '其他的',
    surveyTitle: '调查问题',

    inactivityModalTitle: 'Attention',
    inactivityModalQuestion: 'Are you still using this page?',
    inactivityModalYesButton: 'Yes',

    surveyQuestionsDisclaimerTerms: '*此处包含的信息是保密的。它仅用于调查研究和内部使用。它与任何形式的销售或促销活动无关。严禁复制其全部或部分内容。',

    selectOneWithShortAnswerTitle: '其他烟草品牌产品(请说明)',

    clickNextToProceed: '点击下一题继续',
    clickSubmitToProceed: '点击下一步继续',
};
