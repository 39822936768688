import I18n from 'i18n-js';
import Utils from 'lib/Utils';

import en from './locales/en';
import zh from './locales/zh';
import ms from './locales/ms';

const getLanguageCode = () => {
    const language: string | null = Utils.LocalStorage.getItem('settingsUserLanguage');

    if (language) return language;

    const browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language;

    if (browserLanguage) {
        if (browserLanguage.indexOf('en') === 0) return 'en';
        if (browserLanguage.indexOf('zh') === 0) return 'zh';
        if (browserLanguage.indexOf('ms') === 0) return 'ms';
    }

    // fallback to malay
    return 'ms';
};

I18n.locale = getLanguageCode();

I18n.fallbacks = true;
I18n.translations = {
    en,
    zh,
    ms,
};

export default I18n;
