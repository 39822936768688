import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import Text from 'components/Text';
import ProgressBar from 'components/ProgressBar';
import SurveyFooter from 'components/SurveyFooter';

import { ISurvey } from 'entities/surveys';

import NavActions from 'lib/NavActions';

import { ILanguagePack, ITrackStageTimeParams } from 'redux/slices/home/types';

import Translate from 'translate/Translate';

import dayjs from 'dayjs';
import { ISurveyScanStageEnum } from 'api/HomeBase';
import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import SurveyQuestions from './SurveyQuestions';

import { ContainerStyles, ItemStyles } from './styles';

interface SurveyScreenProps {
    startSurvey: boolean;
    surveyProgress: number;
    surveyData: ISurvey | null;
    languagePack: ILanguagePack;
    surveyQuestionsLength: number;
    trackStageTime: (params: ITrackStageTimeParams) => void;
    setProgress: (param: number) => void;
}

const SurveyScreen = ({
    startSurvey,
    surveyProgress,
    surveyData,
    languagePack,
    surveyQuestionsLength,
    trackStageTime,
    setProgress,
}: SurveyScreenProps): JSX.Element => {
    const maxQuestions = surveyQuestionsLength;

    const currentQuestion = surveyData?.questions.find((_q, idx) => idx === surveyProgress);
    const isAnswered = currentQuestion?.answered;

    const lastQuestion = surveyData?.questions.length === (surveyProgress + 1);

    const handleProceedClick = () => {
        const questionId = currentQuestion?.id;

        if (isAnswered) {
            if (surveyData?.questions.length === (surveyProgress + 1)) {
                submitClickHandler();
                return;
            }
            setProgress(surveyProgress + 1);
            toast.dismiss();
        } else {
            const toastErrorMessage = Translate.t('surveyQuestionNoAnswerToast');
            toast.error(toastErrorMessage, {
                toastId: `${questionId ?? 'question'}`,
            });
        }
    };

    const renderBody = () => {
        return <SurveyQuestions />;
    };

    const submitClickHandler = () => {
        if (surveyData && surveyData.questions && surveyData.questions.length > 0) {
            const notAnswered = surveyData.questions.find(item => item.answered === false);

            if (notAnswered) {
                toast.error(Translate.t('surveyFillAllSurveyErrorMessage'));
                return;
            }

            // Submit Time Tracker
            const endTime = dayjs().toISOString();
            const params = { stage: ISurveyScanStageEnum.BondStAnsweringSurvey, startTime, endTime };
            trackStageTime(params);

            Utils.LocalStorage.clearAnswer();
            NavActions.navToRewards();
        }
    };

    const renderSurveyName = () => {
        return Translate.t('surveyTitle');
    };

    const renderProgressBar = () => {
        if (!surveyData) {
            return null;
        }
        return (
            <ProgressBarContainer>
                {surveyData.questions.map((_, idx) => (
                    <ProgressBar
                        surveyProgress={surveyProgress}
                        index={idx}
                    />
                ))}
            </ProgressBarContainer>
        );
    };

    const [startTime, setStartTime] = useState('');
    useEffect(() => {
        if (startSurvey) {
            const time = dayjs().toISOString();
            setStartTime(time);
        }
    }, [startSurvey]);

    return (
        <>
            <Container>
                <div style={ContainerStyles.titleAndDescContainer}>
                    <Text css={ItemStyles.title}>{renderSurveyName()}</Text>
                    <Text css={ItemStyles.desc}>{languagePack?.surveyDisclaimer}</Text>
                </div>
                <QuestionProgressContainer>
                    {`Question ${surveyProgress + 1} of ${maxQuestions}`}
                </QuestionProgressContainer>
                {renderProgressBar()}
                {renderBody()}
                <Text css={ItemStyles.disclaimer}>{Translate.t('surveyQuestionsDisclaimerTerms')}</Text>
                <SurveyFooter
                    questions={surveyData?.questions ?? []}
                    onSubmit={submitClickHandler}
                />

            </Container>
            { isAnswered ? (
                <ButtonContainer>
                    <ProceedButton
                        onClick={handleProceedClick}
                    >
                        {lastQuestion ? <Text>{Translate.t('clickSubmitToProceed')}</Text> : <Text>{Translate.t('clickNextToProceed')}</Text>}
                    </ProceedButton>
                </ButtonContainer>
            ) : null }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    startSurvey: Selectors.surveyGetAllSurveysAttempting(state),
    surveyData: Selectors.surveyGetAllSurveys(state),
    surveyQuestionsLength: Selectors.homeGetSurveyQuestionsLength(state),
    surveyProgress: Selectors.surveyGetSurveyProgress(state),
    languagePack: Selectors.homeGetLanguagePack(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    trackStageTime: (params: ITrackStageTimeParams) => dispatch(Actions.homeTrackStageTimeAttempt(params)),
    setProgress: (params: number) => dispatch(Actions.surveySetProgress(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyScreen);

const Container = styled.div`
    padding: 20px;
    height: 100%;
    position: relative;
`;

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: .75rem;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    max-width: 440px;
    width: 100vw;
`;

const ProceedButton = styled.button`
    width: 100%;
    height: 56px;
    border: none;
    background-color: #32e344;
    color: black;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    border: 1px solid #031b7b;
`;

const QuestionProgressContainer = styled.div`
    display: flex;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    height: .75rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: white;
`;
