import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import NavActions from 'lib/NavActions';

import Actions from 'redux/Actions';
import { ISubmitBasicInfoParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import { IQrTypeEnum } from 'api/HomeBase';
import Utils from 'lib/Utils';

export default function* watchSubmitBasicInfo(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSubmitBasicInfoAttempt', handleSubmitBasicInfo, api);
}

function* handleSubmitBasicInfo(api: HomeGateway, data: PayloadAction<ISubmitBasicInfoParams>) {
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!scanId) {
        yield put(Actions.homeSubmitBasicInfoFailure(''));

        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.submitBasicInfo], { scanId, ...data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeSubmitBasicInfoFailure(''));

        if (response.name === '1503') {
            NavActions.navToUnqualified();
        } else {
            if (response.name) {
                yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
            } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

            NavActions.navToError();
        }
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        NavActions.navToSurvey();

        yield put(Actions.surveySetSurveyHasBasicInfo(true));
        yield put(Actions.homeSubmitBasicInfoSuccess(response.data));
    }
}
