import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorReduxState } from './types';

const initialState: ErrorReduxState = {
    error: '',
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        errorSetGeneralErrorPageMessage: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload) {
                state.error = action.payload;
            }
        },
    },
});

export type ErrorState = typeof initialState;

export default {
    actions: errorSlice.actions,
    reducers: errorSlice.reducer,
};
