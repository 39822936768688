import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import Utils from 'lib/Utils';

import Translate from 'translate/Translate';
import Actions from 'redux/Actions';
import { ITrackScanParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import NavActions from 'lib/NavActions';
import { IQrTypeEnum } from 'api/HomeBase';
import InactiveMessage from 'lib/LOVs/InactiveSurvey';

import SurveyGateway from 'api/Survey';

export default function* watchTrackScan(api: HomeGateway, surveyApi: SurveyGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeTrackScanAttempt', handleTrackScan, api, surveyApi);
}

function* handleTrackScan(api: HomeGateway, surveyApi: SurveyGateway, data: PayloadAction<ITrackScanParams>) {
    const { qrId, ipAddress, deviceType, os, mobileModel, browser } = data.payload;

    if (!qrId) {
        yield put(Actions.homeTrackScanFailure('Something went wrong. Please try again'));
        return;
    }

    const response = yield* call([api, api.trackScan], { qrId, ipAddress, deviceType, os, mobileModel, browser });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.name === '1003') {
            yield put(Actions.homeTrackScanFailure());
            let brandTitle = 'Marlboro Vista';
            let subTitle = 'Marlboro';
            const getSurveyFlavorResponse = yield* call([surveyApi, surveyApi.getSurveyFlavor], { id: qrId });
            if (getSurveyFlavorResponse.status === GatewayResponseStatus.Success) {
                const { surveyFlavor } = getSurveyFlavorResponse.data;
                brandTitle = surveyFlavor;
                subTitle = surveyFlavor.split(' ')[0] ?? surveyFlavor;
            }
            const errorMessage = Translate.t('surveyInactiveErrorMessage')
                .replace(new RegExp(InactiveMessage.mainTitle, 'g'), brandTitle)
                .replace(new RegExp(InactiveMessage.subTitle, 'g'), subTitle);
            yield put(Actions.errorSetGeneralErrorPageMessage(errorMessage));
            NavActions.navToError();
            return;
        }

        if (response.code === 'NETWORK_ERROR') {
            yield put(Actions.homeTrackScanFailure(''));

            if (response.name) {
                yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
            } else yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

            NavActions.navToError();
        } else yield put(Actions.homeTrackScanFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        Utils.LocalStorage.setItem('scanId', response.data.scanId);
        yield put(Actions.homeGetScanInfoAttempt());
        yield put(Actions.homeTrackScanSuccess(response.data));

        if (response.data.qrType === IQrTypeEnum.AnswerOnce) {
            NavActions.navToLanguageSelection(qrId);
        } if (response.data.qrType === IQrTypeEnum.AnswerMultiple) {
            NavActions.navToLanguageSelectionAndDOB(qrId);
        }
    }
}
