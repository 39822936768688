import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IDataToSubmit } from 'entities/surveys';

import Text from 'components/Text';

import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/SelectMultipleStyles';

interface ShortFormAnswerProps {
    surveyId: string;
    questionId: string;
    question: string;
    images: string[];
    loading: boolean;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const ShortFormAnswer: FunctionComponent<ShortFormAnswerProps> = (props: ShortFormAnswerProps) => {
    const { surveyId, questionId, question, images, loading, changeQuestionStatus, submitAnswer } = props;

    const [answered, setAnswered] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>();

    useEffect(() => {
        if (answered) {
            const dataToSubmit: IDataToSubmit = {
                surveyId,
                questionId,
                answer,
            };
            changeQuestionStatus(questionId, true);
            submitAnswer(dataToSubmit);
        } else {
            changeQuestionStatus(questionId, false);
        }
    }, [answered]);

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    const handleAnswerInput = (answerData: string) => {
        if (answerData.trim().length === 0) {
            setAnswered(false);
        } else {
            setAnswer(answerData);
            setAnswered(true);
        }
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {renderQuestion()}

            {images.length > 0 && (
                <Grid
                    isOnlyOne={images.length === 1}
                >
                    {images.map(url => {
                        return (
                            <img
                                key={url}
                                src={url}
                                alt='no'
                                id='img'
                            />
                        );
                    })}
                </Grid>
            )}

            <div style={ContainerStyles.buttonsContainer}>
                <div>
                    <AnswerInput
                        rows={5}
                        onBlur={(e) => handleAnswerInput(e.target.value)}
                    />
                </div>
                {loading && (
                    <div style={ContainerStyles.loadingOverlay} />
                )}
            </div>
        </div>
    );
};

export const AnswerInput = styled.textarea`
    width: 100%;
    padding: 10px;
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

export default connect(null, mapDispatchToProps)(ShortFormAnswer);
