import Check from './Check.svg';
import Copy from './Copy.svg';
import Groove from './Groove.svg';
import ChevronDown from './chevronDown.svg';
import LeftArrow from './leftArrow.svg';
import FlagMalaysia from './flag-my.svg';
import FlagUK from './flag-uk.svg';
import FlagChina from './flag-cn.svg';

export default {
    Check,
    Copy,
    Groove,
    ChevronDown,
    LeftArrow,
    FlagMalaysia,
    FlagUK,
    FlagChina,
};
