import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ContainerStyles, ItemStyles } from 'containers/home/styles';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import Translate from 'translate/Translate';
import Text from 'components/Text';

interface ErrorProps {
    error: string;
    setError: (error: string) => void;
}

const Error = (props: ErrorProps): JSX.Element => {
    const {
        error,
        setError,
    } = props;

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        return () => {
            setError('');
        };
    });

    return (
        <div style={ContainerStyles.errorContainer}>
            <Text style={{ whiteSpace: 'pre-line' }} css={ItemStyles.errorText}>
                {error.length ? `${error}` : Translate.t('generalError')}
            </Text>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    error: Selectors.errorGetError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setError: (error: string) => dispatch(Actions.errorSetGeneralErrorPageMessage(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Error);
