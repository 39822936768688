import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import ReactMarkdown from 'react-markdown';

import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';

import { IOption, IDataToSubmit } from 'entities/surveys';
import Translate from 'translate/Translate';

import icons from 'assets/icons';

import Text from 'components/Text';

import Selectors from 'redux/Selectors';
import { LanguageSelectionEnum } from 'api/HomeBase';
import Grid from './Grid';

import { ContainerStyles, ItemStyles } from './styles/SelectOneStyles';
import { AnswerInput } from './ShortFormAnswer';

interface RadioButtonProps {
    selected: boolean;
}

const OPTION_SHORT_ANSWER = 'short_answer';

const RadioButton = styled.button<RadioButtonProps>`
    height: 30px;
    width: 30px;

    border: none;

    background-color: transparent;

    display: flex;
    flex-basis: 50px;
    justify-content: center;
    align-items: center;

    color: ${props => (props.selected ? '#021778' : 'white')};

    #outer {
        position: absolute;
        color: white;
        width: 30px;
        height: 30px;

        border-radius: 50%;

        border: ${props => (props.selected ? '1px solid #021778' : '1px solid #A5AAB5')};
    }

    #circle {
        position: absolute;

        width: 20px;
        height: 20px;
    }
`;

interface SelectOneProps {
    surveyId: string;
    questionId: string;
    question: string;
    selectedAnswer?: string;
    options: IOption[];
    images: string[];
    loading: boolean;
    withShortFormAnswer?: boolean;
    selectedLanguage: LanguageSelectionEnum;
    changeQuestionStatus: (questionId: string, answered: boolean) => void;
    submitAnswer: (params: IDataToSubmit) => void;
}

const SelectOne: FunctionComponent<SelectOneProps> = (props: SelectOneProps) => {
    const { surveyId, questionId, selectedAnswer, question, options, images, loading, withShortFormAnswer, selectedLanguage, changeQuestionStatus, submitAnswer } = props;

    const [selected, setSelected] = useState('');
    const [answer, setAnswer] = useState<string>();

    const chesterfield = [
        'Chesterfield Remix Mint – with FRESH ZONE that reduces smoke smell on your fingers when you rub it',
        'Chesterfield Remix Mint – dengan FRESH ZONE untuk mengurangkan bau asap pada jari anda apabila digosok',
        'Chesterfield Remix Mint – 备有FRESH ZONE功能，只需稍微摩擦即可减少手指上的烟味'];

    const marlboroIce = [
        'Marlboro Ice Blast high cooling menthol with capsule and Smoke Smell Control features',
        'Marlboro Ice Blast – rasa dingin mentol yang kuat dengan kapsul dan ciri kawalan Smoke Smell Control',
        'Marlboro Ice Blast – 高度清凉舒爽薄荷醇，备有胶囊和烟味控制功能',
    ];

    const chesterfieldRed = [
        'Chesterfield Red - with FRESH ZONE to reduce smoke smell on your fingers when you rub it',
        'Chesterfield Red - dengan FRESH ZONE untuk mengurangkan bau asap pada jari anda apabila digosok.',
        'Chesterfield Red – 备有FRESH ZONE功能，只需稍微摩擦即可减少手指上的烟味',
    ];

    const marlboroRed = [
        'Marlboro Red',
    ];

    const isAnswerInList = (chosenAnswer: string) => {
        return (
            chesterfield.includes(chosenAnswer)
        || marlboroIce.includes(chosenAnswer)
        || chesterfieldRed.includes(chosenAnswer)
        || marlboroRed.includes(chosenAnswer)
        );
    };

    useEffect(() => {
        if (selectedAnswer && selectedAnswer.length > 0) {
            if (withShortFormAnswer) {
                if (!isAnswerInList(selectedAnswer)) {
                    setAnswer(selectedAnswer);
                    onShortAnswerRadioClicked();
                } else {
                    radioClickHandler(selectedAnswer);
                }
            } else {
                radioClickHandler(selectedAnswer);
            }
        }
    }, []);

    useEffect(() => {
        submitAnswerToApi();
    }, [selected]);

    const submitAnswerToApi = () => {
        // 2 scenarios to handle here:
        // 1) It's a normal select one, in which case we'll need to submit the option
        // 2) It's a short form answer (withShortFormAnswer = true), in which case we'll need to submit the answer as a value
        if (!selected) {
            return;
        }

        // Scenario 1
        if (!withShortFormAnswer) {
            const dataToSubmit = {
                surveyId,
                questionId,
                selectedOptionId: selected,
            };

            submitAnswer(dataToSubmit);
            changeQuestionStatus(questionId, true);
        }

        // Scenario 2
        if (withShortFormAnswer) {
            // user has opted for a custom answer
            if (selected === OPTION_SHORT_ANSWER) {
                if (answer && answer.trim().length > 0) {
                    const dataToSubmit = {
                        surveyId,
                        questionId,
                        answer,
                    };

                    submitAnswer(dataToSubmit);
                    changeQuestionStatus(questionId, true);
                } else {
                    // if there is no short answer entered and this option is entered
                    changeQuestionStatus(questionId, false);
                }
            }

            // change the selected value based on the selected language when user pressed back navigation

            if (selected === chesterfield[0] && selectedLanguage === LanguageSelectionEnum.Chinese) {
                setSelected(chesterfield[2]);
            } else if (selected === chesterfield[0] && selectedLanguage === LanguageSelectionEnum.Malay) {
                setSelected(chesterfield[1]);
            } else if (selected === marlboroIce[0] && selectedLanguage === LanguageSelectionEnum.Chinese) {
                setSelected(marlboroIce[2]);
            } else if (selected === marlboroIce[0] && selectedLanguage === LanguageSelectionEnum.Malay) {
                setSelected(marlboroIce[1]);
            } else if (selected === chesterfieldRed[0] && selectedLanguage === LanguageSelectionEnum.Chinese) {
                setSelected(chesterfieldRed[2]);
            } else if (selected === chesterfieldRed[0] && selectedLanguage === LanguageSelectionEnum.Malay) {
                setSelected(chesterfieldRed[1]);
            }

            // user has opted for an option

            if (chesterfield.some(sentence => sentence === selected)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: chesterfield[0],
                };
                submitAnswer(dataToSubmit);
                changeQuestionStatus(questionId, true);
            } else if (marlboroIce.some(sentence => sentence === selected)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: marlboroIce[0],
                };
                submitAnswer(dataToSubmit);
                changeQuestionStatus(questionId, true);
            } else if (chesterfieldRed.some(sentence => sentence === selected)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: chesterfieldRed[0],
                };
                submitAnswer(dataToSubmit);
                changeQuestionStatus(questionId, true);
            } else if (marlboroRed.some(sentence => sentence === selected)) {
                const dataToSubmit = {
                    surveyId,
                    questionId,
                    answer: marlboroRed[0],
                };
                submitAnswer(dataToSubmit);
                changeQuestionStatus(questionId, true);
            }
        }
    };

    const handleAnswerOnBlur = () => {
        submitAnswerToApi();
    };

    const onShortAnswerChanged = (value: string) => {
        setAnswer(value);

        if (value.trim().length === 0 || value === '') {
            changeQuestionStatus(questionId, false);
        } else {
            changeQuestionStatus(questionId, true);
        }
    };

    const onShortAnswerRadioClicked = () => {
        setSelected(OPTION_SHORT_ANSWER);
    };

    const radioClickHandler = (value: string) => {
        setSelected(value);
    };

    const renderQuestion = () => {
        let description = '';
        let header = '';
        const descriptionIndex = question.search('\n\n');

        if (descriptionIndex !== -1) {
            description = question.slice(descriptionIndex);
            header = question.slice(0, descriptionIndex);
        } else header = question;

        return (
            <div style={ContainerStyles.questionContainer}>
                <Text css={ItemStyles.question}>
                    {header}
                </Text>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            {renderQuestion()}

            {images.length > 0 && (
                <Grid
                    isOnlyOne={images.length === 1}
                >
                    {images.map(url => {
                        return (
                            <img
                                key={url}
                                src={url}
                                alt='no'
                                id='img'
                                style={{ pointerEvents: 'none' }}
                            />
                        );
                    })}
                </Grid>
            )}

            <div style={ContainerStyles.buttonsContainer}>
                {options.map((item) => {
                    const { value, id, image } = item;

                    return (
                        <div
                            key={id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', width: '95%', alignSelf: 'center' }}>
                                {(image && image.length > 0) && (
                                    image?.map(url => {
                                        return (
                                            <img
                                                src={url}
                                                alt='no img'
                                                style={{
                                                    height: '120px',
                                                    minWidth: '120px',
                                                    marginRight: '10px',
                                                    marginTop: '25px',
                                                    marginLeft: '10px',
                                                    objectFit: 'contain',
                                                    marginBottom: '20px',
                                                    alignSelf: 'center',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        );
                                    })
                                )}
                            </div>
                            <div style={ContainerStyles.optionContainer}>
                                <RadioButton
                                    key={id}
                                    onClick={() => radioClickHandler(withShortFormAnswer ? value : id)}
                                    selected={selected === (withShortFormAnswer ? value : id)}
                                    disabled={selected === (withShortFormAnswer ? value : id)}
                                >
                                    <SVG src={icons.Groove} id='outer' />
                                    <SVG src={icons.Groove} id='circle' />
                                </RadioButton>
                                <Text css={ItemStyles.option}>{value}</Text>
                            </div>
                        </div>
                    );
                })}

                {withShortFormAnswer && (
                    <div style={ContainerStyles.optionContainer}>
                        <RadioButton
                            key={OPTION_SHORT_ANSWER}
                            onClick={onShortAnswerRadioClicked}
                            selected={selected === OPTION_SHORT_ANSWER}
                            disabled={selected === OPTION_SHORT_ANSWER} // so that the api wont be called when they spam click
                        >
                            <SVG src={icons.Groove} id='outer' />
                            <SVG src={icons.Groove} id='circle' />
                        </RadioButton>
                        <div style={ContainerStyles.buttonsContainer}>
                            <Text css={ItemStyles.option}>
                                {Translate.t('selectOneWithShortAnswerTitle')}
                            </Text>

                            <div>
                                <AnswerInput
                                    value={answer === undefined ? '' : answer}
                                    rows={2}
                                    onChange={(e) => onShortAnswerChanged(e.target.value)}
                                    onBlur={handleAnswerOnBlur}
                                    disabled={selected !== OPTION_SHORT_ANSWER}
                                />
                            </div>
                            {loading && (
                                <div style={ContainerStyles.loadingOverlay} />
                            )}
                        </div>
                    </div>
                )}

                {loading && (
                    <div style={ContainerStyles.loadingOverlay} />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedLanguage: Selectors.homeGetSelectedLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeQuestionStatus: (questionId: string, answered: boolean) => dispatch(Actions.setQuestionStatus({ questionId, answered })),
    submitAnswer: (params: IDataToSubmit) => dispatch(Actions.submitAnswerAttempt(params)),
});

SelectOne.defaultProps = {
    selectedAnswer: '',
    withShortFormAnswer: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectOne);
