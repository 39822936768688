import { css } from 'styled-components';

export const ContainerStyles = {
    mainContainer: {
        height: 'auto',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        position: 'relative',
    } as const,
    topContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
    } as const,
    bottomContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    } as const,
    welcomeContainer: {
        color: 'black',
        marginTop: '40px',
        fontSize: '14px',
        whiteSpace: 'pre-line',
    } as const,
    pickContainer: {
        marginTop: '20px',
    } as const,
    buttonsContainer: {
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
    } as const,
    disclaimerContainer: {
        backgroundColor: 'white',
        color: 'black',
        fontSize: '13px',
        padding: '10px',
        width: '100%',
        marginBottom: '20px',

    } as const,
    bondFlowDOBContainer: {
        color: 'black',
        fontSize: '14px',
        padding: '5px',
        margin: '20px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    } as const,
    HowToFindCodeContainer: {
        padding: '20px 0',
        width: '50%',
        minHeight: '200px',
        height: '230px',
        border: '1px solid black',
        backgroundColor: '#ffffffae',
    }as const,
};

export const ItemStyles = {
    pageTitle: css`
        font-size: 34px;
        color: white;
        font-weight: bold;
    `,
    pageSubTitle: css`
        font-size: 14px;
        color: white;
    `,
    pickYourBirthday: css`
        font-size: 14px;
        color: white;
        font-weight: bold;
        margin-bottom: 10px;
    `,
    pickYourLanguage: css`
        font-size: 14px;
        color: white;
        font-weight: bold;
        margin-bottom: 10px;
        white-space: pre-line;
    `,
    languageButton: css`
        background-color: white;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 0px;

        margin-top: 5px;
        margin-bottom: 5px;

        height: 60px;

        padding-left: 40px;
        padding-right: 40px;

        font-size: 16px;
    `,
    languageButtonSelected: css`
        background-color: #199be1;
        width: 100%;

        color: white;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 0px;

        margin-top: 5px;
        margin-bottom: 5px;

        height: 60px;

        padding-left: 40px;
        padding-right: 40px;

        font-size: 16px;

        #check {
            width: 25px;
            height: 25px;
        }
    `,
    submitButton: css`
        background-color: #199be1;
        width: 60%;
        border-radius: 0px;
        color: white;
        height: 50px;
        font-size: 16px;
        font-weight: bold;
    `,
    DropdownSelectMonth: css`
        width: 150px;
        margin-right: 20px;
        padding-inline: 15px;
    `,

    DropdownSelectLang: css`
        width: 250px;
    `,

    stepsText: css`
        font-size: 12px;
        text-align: center;
        padding-inline: 10px;
        color: black
    `,
    uniqueCodeText: css`
        font-size: 10px;
        text-align: center;
        padding-inline: 10px;
    `,
    ErrorMessage: {
        marginTop: '5px',
        color: 'red',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
    },
    DisclaimerButtons: {
        background: 'transparent',
        color: '#199be1',
        width: 'auto',
        textDecorationLine: 'underline',
        fontSize: '13px',
        padding: 0,
        height: 'auto',
    },
    CancelButton: {
        background: 'transparent',
        color: 'black',
        marginRight: '40px',
        borderRadius: '0px',
        textDecorationLine: 'underline',
        textUnderlineOffset: '7px',
        letterSpacing: '1px',
        fontWeight: 'bold',
        height: '50px',
    },
    ProceedButton: {
        background: '#199BE1',
        color: 'white',
        borderRadius: '0px',
        letterSpacing: '1px',
        fontWeight: 'bold',
        height: '50px',
        width: '100%',
    },
    DisabledInput: {
        minHeight: '46px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        border: 'none',
        backgroundColor: '#1390D1',
        paddingLeft: '8px',
        fontSize: '15px',
        borderBottom: '1px solid rgb(246,246,246, 0.5)',
        color: 'rgb(246, 246, 246, 0.5)',
    },
};
