import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import NavActions from 'lib/NavActions';
import { IGetCommonCopiesParams } from 'redux/slices/home/types';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchGetCommonCopies(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeGetCommonCopiesAttempt', handleGetCommonCopies, api);
}

function* handleGetCommonCopies(api: HomeGateway, data: PayloadAction<IGetCommonCopiesParams>) {
    const { qrType, qrId } = data.payload;

    const response = yield* call([api, api.getCommonCopies], { qrType, qrId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeGetCommonCopiesFailure(''));
        yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));

        NavActions.navToError();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeGetCommonCopiesSuccess({ qrType, ...response.data }));
    }
}
