import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import HomeScreen from 'containers/home';
import UnqualifiedScreen from 'containers/home/Unqualified';

import LanguageSelectionAndDOBScreen from 'containers/home/bond/LanguageSelectionAndDOB';

import RewardsScreen from 'containers/rewards';

import ErrorScreen from 'containers/Error';

import SurveyScreenV2 from 'containers/v2/surveys';

import PrivateRoute from './PrivateRoutes';

// NOTES:
// If you want to add new routes and is dependent on the stage survey,
// don't forget to modify getScanInfo saga and adjust accordingly

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route path='/' element={<PrivateRoute />}>

            <Route path='/v2/survey' element={<SurveyScreenV2 />} />

            <Route path='/completedSurvey' element={<RewardsScreen />} />
        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/q/:qrId' element={<HomeScreen />} />

                <Route path='/b/q/:qrId' element={<LanguageSelectionAndDOBScreen />} />

                <Route path='/error' element={<ErrorScreen />} />

                <Route path='/unqualified' element={<UnqualifiedScreen />} />

                <Route path='*' element={<Navigate replace to='/' />} />
                {/* nav to general error screen here */}
                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
